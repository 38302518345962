/**
 * Adjust the key width of the card
 */
export function correctCardBodyKeyWidth() {
    const keys = document.getElementsByClassName('card-body-key');
    let maxWidth = 0;
    for (let i = 0; i < keys.length; i++) {
        keys[i].style.width = "auto";
        if (keys[i].getBoundingClientRect().width > maxWidth) {
            maxWidth = keys[i].getBoundingClientRect().width;
        }
    }

    for (let i = 0; i < keys.length; i++) {
        keys[i].style.width = maxWidth + "px";
    }
}