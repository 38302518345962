import React from "react";
import "./LogoutButtonComponent.css"

/**
 * displays the logout button
 * @param userLoggedOut function to log out the user
 * @return {JSX.Element}
 */
export const LogoutButtonComponent = ({userLoggedOut}) => {
    return (
        <div className="flex logout-component auto-width flex-float-right flex-center">
            <button className="button-standard button-brown" onClick={() => userLoggedOut()}>Logout</button>
        </div>
    )
};