import React, {useEffect, useState} from "react";
import "./MainPageComponent.css"
import {MainPageMenuComponent} from "./MainPageMenuComponent";

/**
 *
 * @param content
 * @return {JSX.Element}
 * @constructor
 */
export const MainPageComponent = ({content}) => {
    const [activeComponent, setActiveComponent] = useState(content[0].component);

    /**
     * sets the content of the next showed page
     * @param activePage the page that will be showed next
     */
    const changeActivePage = (activePage) => {
        setActiveComponent(findComponentByKey(activePage))
    }

    /**
     * Finds the associated component with the key
     * @return {null|null|T|T|RTCIceComponent|*}
     * @todo i dont think that's the best way to handle this
     * @param identifier
     */
    const findComponentByKey = (identifier) => {
        for (let i = 0; i < content.length; i++) {
            if (content[i].identifier === identifier)
                return content[i].component;
        }
        return null;
    }

    return (
        <div className="main-page">
            <MainPageMenuComponent
                content={content}
                changeActivePage={changeActivePage}
            />

            <div className="main-page-content">
                {activeComponent}
            </div>
        </div>
    )
}
