import React, {useState} from "react";
import {ModalContent, ModalFooter, useDialog} from "react-st-modal";
import "../../stylesheets/customdialog/CustomDialog.css"

export const AddConnectionDialogComponent = ({connectionsList, keyPrefix, pubKeyOrAddress}) => {
    const dialog = useDialog();
    const [nameValue, setNameValue] = useState("");
    const [pubKeyValue, setPubKeyValue] = useState("");

    const buttonClicked = () => {
        // Check the values
        if (nameValue.length === 0 || pubKeyValue.length === 0) {
            alert("Beide Felder müssen ausgefüllt sein!");
            return;
        }

        // Check if name is already used
        for (const key in connectionsList) {
            if (connectionsList[key].key === "connection_" + keyPrefix + "_" + nameValue) {
                alert("Name ist bereits vergeben.");
                return;
            }
        }

        dialog.close([{key: "connection_" + keyPrefix + "_" + nameValue, value: pubKeyValue}]);
    }

    return (
        <div>
            <ModalContent>
                <p className="">Name<input className="tab-input full-width" type="text" onChange={e => setNameValue(e.target.value)}/></p>
                <p className="">{pubKeyOrAddress}<input className="tab-input full-width" type="text" onChange={e => setPubKeyValue(e.target.value)}/></p>
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-green" onClick={() => buttonClicked()}>Abschicken</button>
            </ModalFooter>
        </div>
    )
}