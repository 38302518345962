import React, {useEffect, useState} from "react";
import {buildEjotDataHeaderList} from "../../builder/EjotBuilder";
import userImage from "../../ressources/icons/user.png"
import connectionImage from "../../ressources/icons/people.png"
import {MainPageComponent} from "../../@shared/components/mainpagecomponent/MainPageComponent";
import {AccountInformationComponent} from "../../@shared/components/accountinformationcomponent/AccountInformationComponent";
import {ConnectionComponent} from "../../@shared/components/connectioncomponent/ConnectionComponent";
import {EjotDataComponent2} from "../../components/ejotdatacomponent/EjotDataComponent2";

export const EjotPageContentComponent = ({activePage, accountInformation, connectionSuppliersList, suppliersData,
                                             uploadDataToBlockchainWithSharedKey, uploadDataToBlockchainWithPrivateKey, deleteDataFromBlockchain}) => {

    const selectPage = () => {
        if (activePage === "mainpage") {
            return (
                <MainPageComponent
                    content={[
                        {"title": "Kontoinformationen", "identifier": "accountInformation", "defaultImage": userImage,
                            "component": <AccountInformationComponent accountInformation={accountInformation}/>},
                        {"title": "Verknüpfung", "identifier": "connections", "defaultImage": connectionImage,
                            "component": <ConnectionComponent connectionsList={connectionSuppliersList}
                                                              deleteDataFromBlockchain={deleteDataFromBlockchain}
                                                              uploadDataToBlockchain={uploadDataToBlockchainWithPrivateKey}
                                                            keyPrefix={"ejotSupplier"}/>},
                    ]}
                />
            )
        } else if (activePage === "data") {
            return <EjotDataComponent2 suppliersData={suppliersData} connectionsList={connectionSuppliersList} headerList={buildEjotDataHeaderList()}
                                       uploadDataToBlockchainWithSharedKey={uploadDataToBlockchainWithSharedKey}/>
        }
    }

    return (
        <div className="flex full-height full-width main-content">
            {selectPage()}
        </div>
    )
}