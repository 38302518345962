export const ComboboxComponent = ({list, onChange}) => {
    const build = () => {
        const arr = [];

        for (let i = 0; i < list.length; i++) {
            arr.push(<option key={list[i]} value={list[i]}>{list[i]}</option>)
        }

        return arr;
    }

    return (
        <div className="float-right">
            <select className="combobox text-center" onChange={(e) => onChange(e.target.value)}>
                {build()}
            </select>
        </div>
    )
}