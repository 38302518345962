/**
 * Convert a number to a number with n decimal digits
 * @param value
 * @param decimals
 * @returns {number}
 */
export function fixedDecimals(value, decimals = 2) {
    return +parseFloat(value).toFixed(decimals);
}

/**
 * @deprecated
 * @param key
 * @returns {*}
 */
export function removeYearFromKey(key) {
    // 2020_ph-Wert -> ph-Wert
    let year = parseInt(key.substr(0, 4));
    if (year) {
        return key.substr(5);
    } else {
        return key;
    }
}

/**
 * @deprecated
 * @param key
 * @return {number}
 */
export function getYearInKey(key) {
    // TODO: safety
    return parseInt(key.substr(0, 4));
}

/**
 * @deprecated
 * @param key
 * @return {boolean}
 */
export function isYearInKey(key) {
    let year = parseInt(key.substr(0, 4));
    if (year)
        return true;
    else
        return false;
}