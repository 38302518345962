import {ModalContent, ModalFooter, useDialog} from "react-st-modal";
import React, {useRef} from "react";
import {chooseDecryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

export const EditMultipleValuesDialogComponent = ({dataList, headerList, text = ""}) => {
    const data = useRef([]);
    const dialog = useDialog();

    const buttonClicked = () => {
        if (checkInput() || checkValueChange()) {
            return;
        }

        const dataArr = [];
        for (const key in data.current) {
            dataArr.push(
                {key: data.current[key].key, value: data.current[key].updatedValue}
            )
        }
        dialog.close(dataArr)
    }

    /**
     * Check if a value was required but not set
      * @return {boolean} true when nothing changed, false otherwise
     */
    const checkValueChange = () => {
        for (const key in data.current) {
            if (data.current[key].value !== data.current[key].updatedValue) {
                return false;
            }
        }

        return true;
    }

    const checkInput = () => {
        const tabs = document.getElementsByClassName('edit-multiple-values-tab-input');
        let invalidFound = false;

        for (let i = 0; i < tabs.length; i++) {
            tabs[i].className = tabs[i].className.replace(" tab-invalid", "");
            if (headerList[tabs[i].id].required && tabs[i].value.length === 0) {
                tabs[i].className += " tab-invalid";
                invalidFound = true;
            }
        }
        return invalidFound;
    }

    const findKeyIdentifier = () => {
        for (const key in dataList) {
            if (dataList[key] !== undefined && dataList[key].keyIdentifier !== undefined) {
                return dataList[key].keyIdentifier;
            }
        }
    }

    const build = () => {
        const arr = [];
        let keyIdentifier = findKeyIdentifier();

        for (const key in headerList) {
            // Skip key
            if (headerList[key].extern)
                continue;

            let value;
            let blockchainKey;

            if (dataList[key] !== undefined) {
                value = chooseDecryptedValueOrValue(dataList[key]);
                blockchainKey = dataList[key].key;
            } else {
                value = "";
                blockchainKey = keyIdentifier + "_" + key;
            }

            data.current[key] = {
                key: blockchainKey, value: value, updatedValue: value, required: headerList[key].required,
            };

            arr.push(
                <p>
                    {key} {headerList[key].required ? "(Pflichtfeld)" : ""}
                    <input id={key} className="tab-input full-width edit-multiple-values-tab-input" type={headerList[key].format} defaultValue={value}
                           onChange={(e) => data.current[key].updatedValue = e.target.value}/>
                </p>
            )
        }

        return arr;
    }

    return (
        <div>
            <ModalContent>
                {text}
                {build()}
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-green" onClick={() => buttonClicked()}>Speichern</button>
            </ModalFooter>
        </div>
    )
}