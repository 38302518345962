function KeyException(message) {
    this.message = message;
    this.name = "KeyException";
}

/**
 * converts a key to a more readable format, eg. from Nmin-Jahr_2019/2020_Adresse to Adress
 * @param key
 * @return {string}
 * @throws KeyException when the key doenst include Nmin-Jahr_
 */
export function convertDataKeyToReadableForm(key) {
    // Nmin-Jahr_2019/2020_Adresse
    if (key.includes("Nmin-Jahr_")) {
        return key.substr(20);
    } else {
        throw new KeyException("Key doesnt contain Nmin-Jahr_");
    }
}

/**
 * extracts the year from the key, eg. fromNmin-Jahr_2019/2020_Adresse to 2019/2020
 * @param key
 * @return {string}
 * @throws KeyException when the key doenst include Nmin-Jahr_
 */
export function extractYearFromKey(key) {
    // Nmin-Jahr_2019/2020_Adresse
    if (key.includes("Nmin-Jahr_")) {
        return key.substr(10, 9);
    } else {
        throw new KeyException("Key doesnt contain Nmin-Jahr_");
    }
}

/**
 * Check if the encryptedValue is undefined or empty and chooses depending on it the encrypted value or the normal/unencrypted value
 * @param object
 * @returns string encryptedValue or value
 */
export function chooseEncryptedValueOrValue(object) {
    if (object.encryptedValue !== undefined && object.encryptedValue.length > 0) {
        return object.encryptedValue;
    } else {
        return object.value;
    }
}

/**
 *
 * @param object
 * @return {string|void|*}
 */
export function chooseDecryptedValueOrValue(object) {
    if (object.decryptedValue !== undefined && object.decryptedValue !== null) {
        return object.decryptedValue;
    } else {
        return object.value;
    }
}

/**
 * Removes a string (prefix) from a string
 * @param str
 * @param prefix
 * @return {*}
 */
export function removePrefixFromString(str, prefix) {
    return str.replace(prefix, "");
}