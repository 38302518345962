import React, {useState} from "react";
import {DataSummaryContentComponent} from "./DataSummaryContentComponent";

export const DataSummaryComponent = ({data, uploadDataToBlockchain}) => {
    const [show, setShow] = useState(false);

    const changeVisibility = () => {
        setShow(!show);
    }

    return (
        <>
            {Object.keys(data).length !== 0 ?
                <div className="summary">
                    <div className="">
                        <button className="button-standard button-brown full-width" onClick={() => changeVisibility()}>Zusammenfassung</button>
                    </div>

                    {show ?
                        <DataSummaryContentComponent data={data} uploadDataToBlockchain={uploadDataToBlockchain}/>
                        : null }
                </div>
                : null
            }
        </>
    )
}