import {fixedDecimals} from "./Conversions";
import {wavesToEuro} from "../../api/waves/WavesAPI";

/**
 * Converts the waves balance
 * @param balance
 * @return {number} balance/10^8
 */
export function convertBalance(balance) {
    return balance / (100000000); // 10^8 doesnt work?
}

/**
 * Converts Waves to another currency (either euro or waves)
 * @param waves
 * @return
 */
export function convertCurrency(waves) {
    return fixedDecimals(wavesToEuro(convertBalance(waves))) + " €";
}

