import writeXlsxFile from 'write-excel-file'

export async function writeExcel(data, fileName = "file") {
    await writeXlsxFile(data, {
        fileName: fileName + '.xlsx'
    })
}

export function writeCSV(data, fileName = "file") {
    const encodedUri = encodeURI(data);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".

    //window.open(encodedUri);
}