import {ModalContent, ModalFooter, useDialog} from "react-st-modal";
import React from "react";

/**
 * todo do it
 * @param response
 * @return {JSX.Element}
 * @constructor
 */
export const CompletedTransactionDialogComponent = ({response}) => {
    const dialog = useDialog();
    console.log(response)

    return (
        <>
            <ModalContent>
                Die Transaktion war erfolgreich.
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-red" onClick={() =>   dialog.close()}>Schließen</button>
            </ModalFooter>
        </>
    )
}