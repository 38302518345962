import {convertPromiseToArray} from "../../util/conversions/ConversionsList";
import {getAccountBalance, getAccountDataList, getTransactionList} from "./WavesAPI";

/**
 * class to get all the public available information from the blockchain:
 * - address, accountDataList, transactionList, availableRegularBalance
 */
export class AccountPublicInformation {
    constructor(address, publicKey) {
        this._address = address;
        this._publicKey = publicKey;
        this._availableRegularBalance = null;
        this._accountDataList = null;
        this._transactionList = null;
    }

    async updateAllData() {
        await this.updateAccountBalance();
        await this.updateEncryptedAccountDataList();
        await this.updateTransactionList();
    }

    async updateEncryptedAccountDataList() {
        const accountDataList = await getAccountDataList(this._address);
        this._accountDataList = convertPromiseToArray(accountDataList);
    }

    async updateAccountBalance() {
        const {available} = await getAccountBalance(this._address);
        this._availableRegularBalance = available;
    }

    async updateTransactionList() {
        this._transactionList = await getTransactionList(this._address);
    }

    get accountDataList() {
        return this._accountDataList;
    }

    set accountDataList(value) {
        this._accountDataList = value;
    }

    get availableRegularBalance() {
        return this._availableRegularBalance;
    }

    set availableRegularBalance(value) {
        this._availableRegularBalance = value;
    }

    get transactionList() {
        return this._transactionList;
    }

    set transactionList(value) {
        this._transactionList = value;
    }

    get address() {
        return this._address;
    }

    get publicKey() {
        return this._publicKey;
    }

    set publicKey(value) {
        this._publicKey = value;
    }
}