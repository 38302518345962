import { Signer } from '@waves/signer';
import { ProviderWeb } from '@waves.exchange/provider-web';

// Account 1.
// short dirt ginger hundred buzz angle typical liberty carbon clump insane trash fluid then boss
// PjTTkXU3$

export async function loginProviderWeb() {
    const wavesSigner = new Signer({NODE_URL: 'https://nodes-testnet.wavesnodes.com'});
    const provider = new ProviderWeb('https://testnet.waves.exchange/signer/');
    wavesSigner.setProvider(provider);
    return wavesSigner;
}

function loginProviderCloud() {

}

export async function wavesSignerSendDataTransaction(signer, jsonData) {
    // TODO: the data transaction with signer doesnt work
    const dataTx = await signer.data({
        data: jsonData,
        chainId: 84,
        type: 12,
    }).broadcast();
    dataTx.then(resp => console.log(resp));
}