import React, {useRef, useState} from "react";
import {CustomDialog} from "react-st-modal";
import {ChooseFileNameExtensionDialogComponent} from "../../@shared/components/choosefilenameextensiondialogcomponent/ChooseFileNameExtensionDialogComponent";
import {findConnection, mergeEjotDataCSV, mergeEjotDataExcel, sortDataList} from "../../api/EjotDataAPI";
import {writeCSV, writeExcel} from "../../@shared/util/filewriter/EjotFileWriter";
import {AddDateDialogComponent} from "../../@shared/components/adddatedialogcomponent/AddDateDialogComponent";
import {convertUTCToDate, getCurrentDatePart, getCurrentDateUTC} from "../../@shared/util/conversions/ConversionsTime";
import {chooseDecryptedValueOrValue} from "../../@shared/util/conversions/ConversionDataKey";
import {ButtonDisabledComponent} from "../../@shared/components/buttondisabledcomponent/ButtonDisabledComponent";
import {ComboboxComponent} from "../../@shared/components/comboboxcomponent/ComboboxComponent";

export const EjotDataComponent2 = ({suppliersData, connectionsList, uploadDataToBlockchainWithSharedKey, headerList}) => {
    const currentStockList = useRef([]);
    const recentlyShippedList = useRef([]);
    const historyList = useRef([]);
    const undefinedList = useRef([]);
    const [sortingAlgorithm, setSortingAlgorithm] = useState("Einlagerungsdatum");

    const exportButtonClicked = async () => {
        const result = await CustomDialog(<ChooseFileNameExtensionDialogComponent data={["Excel", "CSV"]} fileName="ejotDataExport"/>, {
            title: 'Dateiform/Name auswählen',
            showCloseIcon: true,
            isCanClose: false,
        });

        if (result === undefined)
            return;

        const {format, fileName} = result;
        if (format === "Excel") {
            const excelDataList = [];

            // Headers
            const excelHeaderList = [];

            excelHeaderList.push(
                {value: "Zulieferer", fontWeight: "bold", align: 'center', backgroundColor: "#e5ded0"}
            )
            for (const key in headerList) {
                excelHeaderList.push(
                    {value: key, fontWeight: "bold", align: 'center', backgroundColor: "#e5ded0"}
                )
            }

            excelDataList.push(excelHeaderList);

            // Rows
            mergeEjotDataExcel([currentStockList.current, recentlyShippedList.current, historyList.current, undefinedList.current], excelDataList, headerList, connectionsList);

            await writeExcel(excelDataList, fileName);
        } else if (format === "CSV") {
            let csvContent = "data:text/csv;charset=utf-8,";

            // Headers
            csvContent += "Zulieferer,"
            for (const key in headerList) {
                csvContent += key;
                csvContent += ",";
            }

            if (Object.keys(headerList).length > 0)
                csvContent = csvContent.slice(0, -1);

            csvContent += "\n";

            // Rows
            csvContent += mergeEjotDataCSV([currentStockList.current, recentlyShippedList.current, historyList.current, undefinedList.current], headerList, connectionsList);

            writeCSV(csvContent, fileName);
        }
    }

    const rowAddButtonClicked = async (data) => {
        // Get the keyIdentifier
        let keyIdentifier;
        let publicKey;
        for (const key in data) {
            if (data[key].keyIdentifier !== undefined && data[key].keyPubKey !== undefined) {
                keyIdentifier = data[key].keyIdentifier;
                publicKey = data[key].keyPubKey;
                break;
            }
        }

        if (keyIdentifier !== undefined && publicKey !== undefined) {
            const result = await CustomDialog(<AddDateDialogComponent minDate={getCurrentDateUTC()} text="Der Zulieferer wird darüber nicht informiert."/>, {
                title: 'Wunschversanddatum hinzufügen',
                showCloseIcon: true,
                isCanClose: false,
            });

            if (result !== undefined) {
                const d = {key: keyIdentifier + "_Wunschversanddatum", value: result};

                uploadDataToBlockchainWithSharedKey([d], publicKey);
            }
        }
    }

    const generateRows = (data, rowEditable) => {
        const rows = [];

        for (const key in data) {
            rows.push(<tr>{generateRow(data[key])}</tr>);
        }
        return rows;
    }

    const generateRow = (data) => {
        const row = [];

        row.push(<td>{findConnection(data, connectionsList)}</td>)
        for (const key in headerList) {
            // Add the add button if the Date was not already set
            if (data[key] === undefined && key === "Wunschversanddatum") {
                row.push(<td><button className="button-standard button-brown full-width" style={{height: "20px"}} onClick={() =>  rowAddButtonClicked(data)}>Hinzufügen</button></td>)
            } else if (data[key] !== undefined) {
                if (headerList[key].format === "date") {
                     row.push(<td>{convertUTCToDate(chooseDecryptedValueOrValue(data[key]))}</td>)
                } else {
                    row.push(<td>{chooseDecryptedValueOrValue(data[key])}</td>)
                }
            } else {
                row.push(<td>-</td>)
            }
        }

        return row;
    }

    const build = () => {
        const currentStockArr = [];
        const recentlyShippedArr = [];
        const historyArr = [];
        const undefinedArr = [];
        const timeInDays = 7;
        const placeholderText = "Keine Daten vorhanden.";

        // Sort the data
        sortDataList(suppliersData, currentStockArr, recentlyShippedArr, historyArr, undefinedArr, timeInDays, sortingAlgorithm)
        currentStockList.current = currentStockArr;
        recentlyShippedList.current = recentlyShippedArr;
        historyList.current = historyArr;
        undefinedList.current = undefinedArr;

        return (
            <>
                <h3>Wunschversanddatum noch nicht festgelegt</h3>
                {Object.keys(undefinedArr).length === 0 ? placeholderText : buildTable(undefinedArr, headerList)}
                <br/>
                <h3>Aktuelle Lagerliste</h3>
                {Object.keys(currentStockArr).length === 0 ? placeholderText : buildTable(currentStockArr, headerList, true)}
                <br/>
                <h3>Kürzlich versendet (letzten {timeInDays} Tage)</h3>
                {Object.keys(recentlyShippedArr).length === 0 ? placeholderText : buildTable(recentlyShippedArr, headerList)}
                <br/>
                <h3>Historie</h3>
                {Object.keys(historyArr).length === 0 ? placeholderText : buildTable(historyArr, headerList)}
            </>
        )
    }

    const buildTable = (data, headers, rowEditable = false) => {
        const rows = generateRows(data, rowEditable);

        return (
            <table className="table ejot-supplier-data-table full-width text-center">
                <tr>
                    <th>Zulieferer</th>
                    {Object.keys(headers).map((key) =>
                        <th>{key}</th>
                    )}
                </tr>

                {rows.map((row) =>
                    <>{row}</>
                )}
            </table>
        )
    }

    const buildPlaceholder = () => {
        return (
            <>
                <br/><br/>
                Keine Daten vorhanden.
            </>
        )
    }

    /**
     * Check if the list has content
     * @return {boolean}
     */
    const isListFilled = () => {
        return (Object.keys(suppliersData).length !== 0);
    }

    const comboboxOnChange = (value) => {
        setSortingAlgorithm(value)
    }

    return (
        <div className="full-width">
            <div>
                Aktuelles Datum: {getCurrentDatePart()}
                <ButtonDisabledComponent disabled={!isListFilled()} clicked={exportButtonClicked} buttonText={"Export"}/>
                <ComboboxComponent list={["Einlagerungsdatum", "Wunschversanddatum"]} onChange={comboboxOnChange}/>
            </div>

            {isListFilled() ? build() : buildPlaceholder()}
        </div>
    )
}