import React, {useState} from "react";
import {ModalContent, ModalFooter, useDialog} from "react-st-modal";

/**
 *
 * @param minDate
 * @param maxDate
 * @param text
 * @return {JSX.Element}
 * @constructor
 */
export const AddDateDialogComponent = ({minDate, maxDate, text = ""}) => {
    const dialog = useDialog();
    const [value, setValue] = useState();

    const submitButtonClicked = () => {
        const tabs = document.getElementsByClassName('add-date-tab-input');
        tabs[0].className = tabs[0].className.replace(" tab-invalid", "");
        if (value === undefined) {
            tabs[0].className += " tab-invalid";
        } else {
            dialog.close(value)
        }
    }

    return (
        <div>
            <ModalContent>
                {text}
                <input className="full-width add-date-tab-input" type="date" min={minDate} max={maxDate} id={0} name={0} onChange={e => setValue(e.target.value)}/>
            </ModalContent>
            <ModalFooter>
                <button className="button-standard button-green" onClick={() => submitButtonClicked()}>Abschicken</button>
            </ModalFooter>
        </div>
    )
}