import React from 'react';
import './App.css';
import "./stylesheets/EjotColorDefinitions.css"

import "./@shared/stylesheets/misc/Main.css"
import "./@shared/stylesheets/misc/Misc.css"
import "./@shared/stylesheets/misc/MiscCSS.css"
import "./@shared/stylesheets/misc/Size.css"
import "./@shared/stylesheets/misc/Placeholder.css"
import "./@shared/stylesheets/misc/Flex.css"
import "./@shared/stylesheets/misc/Button.css"
import "./@shared/stylesheets/misc/Text.css"
import "./@shared/stylesheets/misc/DevMenu.css"
import "./@shared/stylesheets/misc/Summary.css"
import "./@shared/stylesheets/table/Table.css"
import "./@shared/stylesheets/misc/Combobox.css"
import "./@shared/stylesheets/card/CardMenu.css"
import "./@shared/stylesheets/card/Card.css"

import {useState} from "react";
import {EjotPageComponent} from "./page/ejotpage/EjotPageComponent";
import {SimpleSelectDialogComponent} from "./@shared/components/simpleselectdialogcomponent/SimpleSelectDialogComponent";
import {CustomDialog} from "react-st-modal";
import {EjotSupplierPageComponent} from "./page/ejotsupplierpage/EjotSupplierPageComponent";
import {LoginPageComponent} from "./@shared/page/loginpage/LoginPageComponent";
import ejotDraht1 from "./ressources/images/Draht1.jpg"
import ejotDraht2 from "./ressources/images/Draht2.jpg"
import ejotSchrauben1 from "./ressources/images/Schrauben1.jpg"
import ejotSchrauben2 from "./ressources/images/Schrauben2.jpg"

export const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [WavesSelectorAPI, setWavesSelectorAPI] = useState(null);
    const [activePage, setActivePage] = useState("");

    const ejotPublicKeys = ["Dsy1PgR5yuAFT1ppRADxBKsJUnsYQfsU4S9bH9ybqC5x"];
    const ejotSupplierPublicKeys = ["2HSbgwtpee7FB1M66xMKUq9g1DMuywE9QfQgZxQaEa6y", "81GPPHmkptWhpZkY6cV6UBmS5ouoNk6X8ZuJSKsXr6HC"];

    const userLoggedOut = () => {
        if (WavesSelectorAPI !== null)
            WavesSelectorAPI.userLoggedOut();

        setLoggedIn(false);
    }

    const userLoggedIn = async (WavesSelectorAPI) => {
        setWavesSelectorAPI(WavesSelectorAPI);

        const publicKey = WavesSelectorAPI.publicKey;
        let page = "";
        if (ejotPublicKeys.includes(publicKey)) {
            page = "ejot";
        } else if (ejotSupplierPublicKeys.includes(publicKey)) {
            page = "ejotSupplier";
        } else {
            page = await CustomDialog(<SimpleSelectDialogComponent data={["ejot", "ejotSupplier"]}/>, {
                title: 'Dateiform auswählen',
                showCloseIcon: true,
                isCanClose: false,
            });
        }

        setActivePage(page);
        setLoggedIn(true);
    }

    const selectPage = () => {
        if (activePage === "ejot") {
            return (
                <EjotPageComponent
                    WavesSelectorAPI={WavesSelectorAPI}
                    userLoggedOut={userLoggedOut}
                />
            )
        } else if (activePage === "ejotSupplier") {
            return (
                <EjotSupplierPageComponent
                    WavesSelectorAPI={WavesSelectorAPI}
                    userLoggedOut={userLoggedOut}
                />
            )
        }
    }

    return (
        <>
            {loggedIn ?
                selectPage()
                :
                <LoginPageComponent
                    userLoggedIn={userLoggedIn}
                    randomImage={[ejotDraht1, ejotDraht2, ejotSchrauben1, ejotSchrauben2]}
                />
            }
        </>
    )
}

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/

export default App;
