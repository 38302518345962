import React, {useState} from 'react'
import "./LoginPageComponent.css";
import {LoginFormComponent}  from "./form/LoginFormComponent";
import {wavesKeeperInit} from "../../api/waves/WavesKeeperAPI";
import {loginProviderWeb} from "../../api/waves/WavesSignerAPI"
import {WavesSelectorAPI} from "../../api/waves/WavesSelectorAPI";
import {validateAddress} from "../../api/waves/WavesAPI";

/**
 * login screen
 * @param userLoggedIn
 * @return {JSX.Element}
 * @constructor
 */
export const LoginPageComponent = ({userLoggedIn, randomImage}) => {
    /**
     * login with WavesKeeper
     * @return {Promise<void>}
     */
    const loginWavesKeeper = async () => {
        const keeperInit = await wavesKeeperInit();
        const addressIsValid = validateAddress(keeperInit.address);

        if (addressIsValid) {
            const wavesSelectorAPI = new WavesSelectorAPI("WavesKeeper");
            wavesSelectorAPI.keeper = keeperInit;
            userLoggedIn(wavesSelectorAPI);
        } else {
            // todo
        }
    }

    /**
     * login with a key pair (private key & public key & address)
     * @param keyPair
     */
    const loginKeyPair = (keyPair) => {
        const addressIsValid = validateAddress(keyPair.address);

        if (addressIsValid) {
            const wavesSelectorAPI = new WavesSelectorAPI("KeyPair");
            wavesSelectorAPI.keyPair = keyPair;
            userLoggedIn(wavesSelectorAPI);
        } else {
            // todo
        }
    }

    /**
     * login in with WavesSigner
     * @return {Promise<void>}
     */
    const loginWavesSigner = async () => {
        const signer = await loginProviderWeb();
        const addressIsValid = validateAddress(""); // todo check

        if (addressIsValid) {
            const wavesSelectorAPI = new WavesSelectorAPI("WavesSigner");
            wavesSelectorAPI.signer = signer;
            userLoggedIn(wavesSelectorAPI);
        } else {
            // todo
        }
    }

    return (
        <div className="login-page">
            <LoginFormComponent loginKeyPair={loginKeyPair}
                                loginWavesKeeper={loginWavesKeeper}
                                loginWavesSigner={loginWavesSigner}
                                randomImage={randomImage}
            />
        </div>
    );
}