import CryptoJS from 'crypto-js';

/**
 * Encrypt a msg
 * @param msg
 * @param key
 * @return {*}
 * @deprecated
 */
export function encryptMessage(msg, key) {
    return CryptoJS.AES.encrypt(msg.toString(), key);
}

/**
 * decrypt a msg
 * @param encryptedMsg
 * @param key
 * @return {*}
 * @deprecated
 */
export function decryptMessage(encryptedMsg, key) {
    try {
        return CryptoJS.AES.decrypt(encryptedMsg.toString(), key).toString(CryptoJS.enc.Utf8);
    } catch (e) {
        console.log(e);
    }
}