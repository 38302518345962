import React from "react";
import "./ContentImageComponent.css"

/**
 * displays the image
 * @param image the image
 * @param imageText text that will shown in the center of the image
 * @return {JSX.Element}
 */
export const ContentImageComponent = ({image, imageText}) => {
    // TODO: image doesnt keep aspect ratio
    return (
        <div className="main-image">
            <img src={image} alt=""/>
            <h1> {imageText} </h1>
        </div>
    )
}