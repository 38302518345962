import React from "react";
import {CustomDialog} from "react-st-modal";
import {AddConnectionDialogComponent} from "../addconnectiondialogcomponent/AddConnectionDialogComponent";
import {constructCardArrayFromSingleValue} from "../cardcomponent/CardArrayGenerator";
import {CardComponent} from "../cardcomponent/CardComponent";
import {ButtonDisabledComponent} from "../buttondisabledcomponent/ButtonDisabledComponent";
import {chooseDecryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

/**
 *
 * @param connectionsList
 * @param uploadDataToBlockchain
 * @param deleteDataFromBlockchain
 * @param keyPrefix
 * @param title
 * @param maxConnections 0 = unlimited
 * @param pubKeyOrAddress
 * @return {JSX.Element}
 * @todo after uploading a new key, the user can upload another key, even if it means to have more than the maxConnections
 */
export const ConnectionComponent = ({connectionsList, deleteDataFromBlockchain, uploadDataToBlockchain,
                                        keyPrefix, title = "Verknüpfung", maxConnections = 0, pubKeyOrAddress = "Public Key"}) => {
    const buildCards = () => {
        const cardArray = [];

        const connectionArr = [];
        for (const key in connectionsList) {
            constructCardArrayFromSingleValue(
                connectionsList[key].key,
                connectionsList[key].keyKey,
                chooseDecryptedValueOrValue(connectionsList[key]),
                "Shared",
                connectionArr
            );
        }

        cardArray.push(<CardComponent title={title} data={connectionArr} editable={true} uploadDataToBlockchain={uploadDataToBlockchain} deleteDataFromBlockchain={deleteDataFromBlockchain}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    const buildPlaceholder = () => {
        return (
            <div>
                Keine {title} gefunden.
            </div>
        )
    }

    const buttonClicked = async () => {
        const result = await CustomDialog(<AddConnectionDialogComponent connectionsList={connectionsList} keyPrefix={keyPrefix} pubKeyOrAddress={pubKeyOrAddress}/>, {
            title: 'Neue Verknüpfung anlegen',
            showCloseIcon: true,
            isCanClose: false,
        });

        if (result !== undefined) {
            uploadDataToBlockchain(result);
        }
    }

    return (
        <>
            {connectionsList === undefined || connectionsList.length === 0 ? buildPlaceholder() : buildCards()}

            <div className="overflow-auto float-right">
                <ButtonDisabledComponent disabled={maxConnections !== 0 && connectionsList.length >= maxConnections} clicked={buttonClicked} buttonText={"Hinzufügen"}/>
            </div>
        </>
    )
}