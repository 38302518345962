import {getCurrentTimestampMidnight} from "../@shared/util/conversions/ConversionsTime";
import {chooseDecryptedValueOrValue} from "../@shared/util/conversions/ConversionDataKey";

export function sortDataList(suppliersData, currentStockArr, recentlyShippedArr, historyArr, undefinedArr, timeInDays, temp = "Wunschversanddatum") {
    console.log(suppliersData)
    for (const key in suppliersData) {
        if (suppliersData[key][temp] === undefined) {
            undefinedArr[key] = suppliersData[key];
        } else {
            // Convert Date to Timestamp
            let date = suppliersData[key][temp].decryptedValue;
            //date = date.split("-");
            //const dateTimestamp = new Date(date[2], date[1]-1, date[0]).getTime();
            const dateTimestamp = Date.parse(date);

            // Get Today + n
            const currentTimestamp = getCurrentTimestampMidnight();

            // History
            if (dateTimestamp < currentTimestamp - (timeInDays * 24 * 60 * 60 * 1000)) {
                historyArr[key] = suppliersData[key];
            }
            // Current
            else if (dateTimestamp >= currentTimestamp) {
                currentStockArr[key] = suppliersData[key];
            }
            // Recently Shipped
            else if (dateTimestamp > currentTimestamp - (timeInDays * 24 * 60 * 60 * 1000) && dateTimestamp < currentTimestamp) {
                recentlyShippedArr[key] = suppliersData[key];
            } else {
                undefinedArr[key] = suppliersData[key];
            }
        }
    }
}

export function mergeEjotSupplierDataExcel(dataList, excelDataList, headerList) {
    for (let i = 0; i < dataList.length; i++) {
        for (const key in dataList[i]) {
            // Row
            const rowList = [];
            for (const key2 in headerList) {
                if (dataList[i][key][key2] !== undefined) {
                    rowList.push(
                        {type: String, value: chooseDecryptedValueOrValue(dataList[i][key][key2]), align: 'center'}
                    );
                } else {
                    rowList.push({type: String, value: "-", align: 'center'});
                }
            }
            excelDataList.push(rowList);
        }
    }
}

export function mergeEjotSupplierDataCSV(dataList, headerList) {
    let csvContent = "";

    for (let i = 0; i < dataList.length; i++) {
        for (const key in dataList[i]) {
            for (const key2 in headerList) {
                if (dataList[i][key][key2] !== undefined) {
                    csvContent += chooseDecryptedValueOrValue(dataList[i][key][key2]);
                    csvContent += ",";
                } else {
                    csvContent += "-";
                    csvContent += ",";
                }
            }

            csvContent = csvContent.slice(0, -1);
            csvContent += ("\n");
        }
    }

    return csvContent;
}

export function mergeEjotDataExcel(dataList, excelDataList, headerList, connectionsList) {
    for (let i = 0; i < dataList.length; i++) {
        for (const key in dataList[i]) {
            // Row
            const rowList = [];
            rowList.push(
                {type: String, value: findConnection(dataList[i][key], connectionsList), align: 'center'}
            );

            for (const key2 in headerList) {
                if (dataList[i][key][key2] !== undefined) {
                    rowList.push(
                        {type: String, value: chooseDecryptedValueOrValue(dataList[i][key][key2]), align: 'center'}
                    );
                } else {
                    rowList.push({type: String, value: "-", align: 'center'});
                }
            }
            excelDataList.push(rowList);
        }
    }
}

export function mergeEjotDataCSV(dataList, headerList, connectionsList) {
    let csvContent = "";

    for (let i = 0; i < dataList.length; i++) {
        for (const key in dataList[i]) {
            csvContent += findConnection(dataList[i][key], connectionsList) + ",";

            for (const key2 in headerList) {
                if (dataList[i][key][key2] !== undefined) {
                    csvContent += chooseDecryptedValueOrValue(dataList[i][key][key2]);
                    csvContent += ",";
                } else {
                    csvContent += "-";
                    csvContent += ",";
                }
            }

            csvContent = csvContent.slice(0, -1);
            csvContent += ("\n");
        }
    }

    return csvContent;
}

export function findConnection(data, connectionsList) {
    console.log(connectionsList)
    console.log(data)
    for (const key in connectionsList) {
        for (const key2 in data) {
            if (data[key2].keyPubKey === connectionsList[key].decryptedValue) {
                return connectionsList[key].keyKey;
            }
        }
    }
    return "-";
}