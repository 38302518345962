import "./DataSummaryContentComponent.css"
import deleteIcon from "../../ressources/icons/trash-var-outline.png"
import updateIcon from "../../ressources/icons/upload-box-outline.png"
import addIcon from "../../ressources/icons/document-add-outline.png"
import {Confirm} from "react-st-modal";
import {chooseDecryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

export const DataSummaryContentComponent = ({data, uploadDataToBlockchain}) => {
    const build = () => {
        const arr = [];

        arr.push(<button className="button-standard button-green full-width" onClick={() => saveButtonClicked()}>Abschicken</button>)
        arr.push(<br/>)
        arr.push(<br/>)

        for (const key in data) {
            if (data[key].next === "delete") {
                arr.push(<img src={deleteIcon} alt="" title="Löschen"/>)
            } else if (data[key].next === "update") {
                arr.push(<img src={updateIcon} alt="" title="Ändern"/>)
            } else if (data[key].next === "add") {
                arr.push(<img src={addIcon} alt="" title="Hinzufügen"/>)
            }

            arr.push(<div>{data[key].key}</div>);
            arr.push(<div>{chooseDecryptedValueOrValue(data[key])}</div>);
            arr.push(<button className="button-standard button-red full-width" onClick={() => removeButtonClicked(key)}>Remove</button>)
            arr.push(<br/>);
            arr.push(<br/>);
        }

        arr.push(<br/>);

        return arr;
    }

    const removeButtonClicked = (key) => {
        delete data[key];
    }

    const saveButtonClicked = async () => {
        const result = await Confirm('Wollen Sie die Daten nun hochladen?',
            'Bestätigung', "Okay", "Lieber nicht");

        if (result) {
            const formattedData = [];

            for (const key in data) {
                formattedData.push({
                    key: data[key].key, value: data[key].value, type: data[key].type === null ? data[key].type : "string"
                });
            }

            const response = await uploadDataToBlockchain(formattedData)
            if (response) {
                for (const key in data) {
                    delete data[key];
                }
            }
        }
    }

    return (
        <div className="data-summary-content auto-width auto-height">
            {Object.entries(data).length !== 0 ? build() : ""}
        </div>
    )
}