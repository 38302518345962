import React, {useState} from 'react'
import "./LoginFormTitleComponent.css";

export const LoginFormTitleComponent = () => {

    return (
        <div className="login-form-title">
            <span className="login-screen-top-text">Willkommen zurück!<br/>Anmelden</span>
        </div>
    )
}