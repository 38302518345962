export function buildEjotDataHeaderList() {
    const dataHeaderList = [];

    dataHeaderList["Artikelnummer"] = {key: "Artikelnummer", required: true, format: "text", extern: true};
    dataHeaderList["Abmessung"] = {key: "Abmessung", required: true, format: "text", extern: true};
    dataHeaderList["Kilogramm"] = {key: "Kilogramm", required: true, format: "text", extern: true};
    dataHeaderList["Werkstoff"] = {key: "Werkstoff", required: true, format: "text", extern: true};
    dataHeaderList["BestellNr"] = {key: "BestellNr", required: true, format: "text", extern: true};
    dataHeaderList["Charge"] = {key: "Charge", required: true, format: "text", extern: true};
    dataHeaderList["Auftragsnummer"] = {key: "Auftragsnummer", required: false, format: "text", extern: true};
    dataHeaderList["Einlagerungsdatum"] = {key: "Einlagerungsdatum", required: true, format: "date", extern: true};
    dataHeaderList["Wunschversanddatum"] = {key: "Wunschversanddatum", required: true, format: "date", extern: false};

    return dataHeaderList;
}