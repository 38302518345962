import {CustomDialog} from "react-st-modal";
import {ChooseFileNameExtensionDialogComponent} from "../../@shared/components/choosefilenameextensiondialogcomponent/ChooseFileNameExtensionDialogComponent";
import {useRef, useState} from "react";
import {mergeEjotSupplierDataCSV, mergeEjotSupplierDataExcel, sortDataList} from "../../api/EjotDataAPI";
import {writeCSV, writeExcel} from "../../@shared/util/filewriter/EjotFileWriter";
import {EditMultipleValuesDialogComponent} from "../../@shared/components/editmultiplevaluesdialogcomponent/EditMultipleValuesDialogComponent";
import {DataTableComponent} from "../../@shared/components/datatablecomponent/DataTableComponent";
import {getCurrentDatePart} from "../../@shared/util/conversions/ConversionsTime";
import {ButtonDisabledComponent} from "../../@shared/components/buttondisabledcomponent/ButtonDisabledComponent";
import {ComboboxComponent} from "../../@shared/components/comboboxcomponent/ComboboxComponent";

export const EjotSupplierDataComponent = ({supplierDataList, connectionsList, uploadDataToBlockchainWithSharedKey, headerList}) => {
    const currentStockList = useRef([]);
    const recentlyShippedList = useRef([]);
    const historyList = useRef([]);
    const undefinedList = useRef([]);
    const [sortingAlgorithm, setSortingAlgorithm] = useState("Einlagerungsdatum");

    const exportButtonClicked = async () => {
        const result = await CustomDialog(<ChooseFileNameExtensionDialogComponent data={["Excel", "CSV"]} fileName="ejotSupplierDataExport"/>, {
            title: 'Dateiform/Name auswählen',
            showCloseIcon: true,
            isCanClose: false,
        });

        if (result === undefined)
            return;

        const {format, fileName} = result;
        if (format === "Excel") {
            const excelDataList = [];

            // Headers
            const excelHeaderList = [];

            for (const key in headerList) {
                excelHeaderList.push(
                    {value: key, fontWeight: "bold", align: 'center', backgroundColor: "#e5ded0"}
                )
            }

            excelDataList.push(excelHeaderList);

            // Rows
            mergeEjotSupplierDataExcel([currentStockList.current, recentlyShippedList.current, historyList.current, undefinedList.current], excelDataList, headerList);

            await writeExcel(excelDataList, fileName);
        } else if (format === "CSV") {
            let csvContent = "data:text/csv;charset=utf-8,";

            // Headers
            for (const key in headerList) {
                csvContent += key;
                csvContent += ",";
            }

            if (Object.keys(headerList).length > 0)
                csvContent = csvContent.slice(0, -1);

            csvContent += "\n";

            // Rows
            csvContent += mergeEjotSupplierDataCSV([currentStockList.current, recentlyShippedList.current, historyList.current, undefinedList.current], headerList);

            writeCSV(csvContent, fileName);
        }
    }

    const rowButtonClicked = async (data) => {
        const result = await CustomDialog(<EditMultipleValuesDialogComponent dataList={data} headerList={headerList}/>, {
            title: 'EditMultipleValue',
            showCloseIcon: true,
            isCanClose: false,
        });

        if (result === undefined) {
            return;
        }

        uploadDataToBlockchainWithSharedKey(result, data.recieverPublicKey)
    }

    const build = () => {
        const currentStockArr = [];
        const recentlyShippedArr = [];
        const historyArr = [];
        const undefinedArr = [];
        const timeInDays = 7;
        const placeholderText = "Keine Daten vorhanden.";

        // Sort the data
        sortDataList(supplierDataList, currentStockArr, recentlyShippedArr, historyArr, undefinedArr, timeInDays, sortingAlgorithm)
        currentStockList.current = currentStockArr;
        recentlyShippedList.current = recentlyShippedArr;
        historyList.current = historyArr;
        undefinedList.current = undefinedArr;
        //buildTable(currentStockArr, headerList, true)
        return (
            <>
                <h3>Aktuelle Lagerliste</h3>
                {Object.keys(currentStockArr).length === 0 ? placeholderText : <DataTableComponent dataList={currentStockArr} headerList={headerList} rowEditable={true} rowClicked={rowButtonClicked}/>}
                <br/>
                <h3>Wunschversanddatum noch nicht festgelegt</h3>
                {Object.keys(undefinedArr).length === 0 ? placeholderText : <DataTableComponent dataList={currentStockArr} headerList={headerList}/>}
                <br/>
                <h3>Kürzlich versendet (letzten {timeInDays} Tage)</h3>
                {Object.keys(recentlyShippedArr).length === 0 ? placeholderText : <DataTableComponent dataList={currentStockArr} headerList={headerList}/>}
                <br/>
                <h3>Historie</h3>
                {Object.keys(historyArr).length === 0 ? placeholderText : <DataTableComponent dataList={currentStockArr} headerList={headerList}/>}
            </>
        )
    }

    const buildPlaceholder = () => {
        return (
            <>
                <br/><br/>
                Keine Daten vorhanden.
            </>
        )
    }

    /**
     * Check if the list has content
     * @return {boolean}
     */
    const isListFilled = () => {
        return (supplierDataList === undefined || Object.keys(supplierDataList).length !== 0);
    }

    const comboboxOnChange = (value) => {
        setSortingAlgorithm(value)
    }

    return (
        <div className="full-width">
            <div>
                Aktuelles Datum: {getCurrentDatePart()}
                <ButtonDisabledComponent disabled={!isListFilled()} clicked={exportButtonClicked} buttonText={"Export"}/>
                <ComboboxComponent list={["Einlagerungsdatum", "Wunschversanddatum"]} onChange={comboboxOnChange}/>
            </div>
            {isListFilled() ? build() : buildPlaceholder()}
        </div>
    )
}