import React from "react";

/**
 *
 * @param disabled
 * @param clicked
 * @param buttonText
 * @return {JSX.Element}
 * @constructor
 */
export const ButtonDisabledComponent = ({disabled, clicked, buttonText}) => {
    return (
        <button className={`button-standard float-right cooperation-data-export-button ${disabled ? "button-disabled" : "button-brown"}`}
                disabled={disabled} onClick={() => clicked()}>{buttonText}</button>
    )
}
