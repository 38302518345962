import { ModalContent, ModalFooter, ModalButton, useDialog } from 'react-st-modal';
import React, {useState} from "react";

/**
 * A complex dialog that gets a list and returns a specific string
 * @param data object with key and value
 * @return {JSX.Element}
 * @constructor
 */
export const ComplexSelectDialogComponent = ({data}) => {
    const dialog = useDialog();
    const [value, setValue] = useState("");

    const build = () => {
        const arr = [];

        for (const key in data) {
            arr.push(<input type="radio" name="choice" value={data[key]} onClick={event => setValue(event.target.value)}/>)
            arr.push(<> {key} | {data[key]} </>);
            arr.push(<br/>)
        }

        return arr;
    }

    const buttonClicked = () => {
        if (value.length === 0) {
            alert("Bitte wählen Sie etwas aus.")
        } else {
            dialog.close(value);
        }
    }

    return (
        <div>
            <ModalContent>
                {build()}
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-green" onClick={() => buttonClicked()}>Abschicken</button>
            </ModalFooter>
        </div>
    )
}