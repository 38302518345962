import React, {useRef, useState} from "react";
import {Confirm, ModalContent, ModalFooter, useDialog} from "react-st-modal";
import "./EditTableDialogComponent.css"
import {convertDateToDatePart} from "../../util/conversions/ConversionsTime";

export const EditTableDialogComponent = ({rows, headerList}) => {
    const dialog = useDialog();
    const [editable, setEditable] = useState(false);
    const dataList = useRef([]);

    const merge = () => {
        const arr = [];

        for (let i = 1; i < rows.length; i++) {
            for (let j = 0; j < rows[i].length; j++) {

                if (arr[i] === undefined) {
                    arr[i] = [];
                    arr[i].key = i;
                }

                arr[i][rows[0][j]] = {key: rows[i][j], header: headerList[rows[0][j]]};

                //console.log("Header: " + rows[0][j])
                //console.log(rows[i][j])
                //console.log("----")
            }
        }
        return arr;
    }

    const buildHeader = () => {
        const h = [];

        for (const key in headerList) {
            if (!headerList[key].extern)
                h.push(<th>{key}</th>)
        }

        /*for (let i = 0; i < rows[0].length; i++) {
            if (headerList[rows[0][i]] !== undefined) {
                h[rows[0][i]] = {key: rows[0][i], index: i, table: <th>{rows[0][i]}</th>}
            }
        }*/

        return h;

        const header = [];
        for (let i = 0; i < rows[0].length; i++) {
            header.push(<th>{rows[0][i]}</th>);
        }
        return header;
    }

    const buildRow = (row) => {
        const tableRow = [];

        for (const key in headerList) {
            // Skip key if the origin is extern
            if (headerList[key].extern) {
                continue;
            }

            if (row[key] !== undefined) {
                if (row[key].key instanceof Date) {
                    tableRow.push(<td id={key} contentEditable={editable}><input readOnly={!editable} type="date" style={{width: "165px"}} defaultValue={convertDateToDatePart(row[key].key)}/></td>)
                } else {
                    tableRow.push(<td id={key} contentEditable={editable}>{row[key].key}</td>)
                }
            } else {
                tableRow.push(<td id={key} contentEditable={editable}>wut</td>)
            }
        }

        return tableRow;
    }

    const build = () => {
        dataList.current = merge();
        const tableArray = [];

        // Header
        tableArray.push(<tr>{buildHeader()}</tr>);

        // Rows
        for (const key in dataList.current) {
            tableArray.push(<tr>{buildRow(dataList.current[key])}</tr>);
        }

        return tableArray;
    }

    const submitButtonClicked = () => {
        const data = [];
        let invalidFound = false;

        //gets table
        const oTable = document.getElementById('edit-table-dialog');

        // loops through rows
        for (let i = 1; i < oTable.rows.length; i++) {
            data[i-1] = [];
            // loops through each cell in current row
            for (let j = 0; j < oTable.rows.item(i).cells.length; j++) {
                const headerKey = oTable.rows.item(i).cells.item(j).id;
                let obj;

                let cellVal;
                if (headerList[headerKey].format === "date") {
                    obj = oTable.rows.item(i).cells.item(j).children[0];
                    cellVal = oTable.rows.item(i).cells.item(j).children[0].value;
                } else {
                    obj = oTable.rows.item(i).cells.item(j);
                    cellVal = oTable.rows.item(i).cells.item(j).innerHTML;
                }

                obj.className = obj.className.replace(" table-invalid", "");

                if (cellVal.match("^<br>$")) {
                    cellVal = "";
                }

                if (cellVal.length === 0 && headerList[headerKey].required) {
                    obj.className += " table-invalid";
                    invalidFound = true;
                }

                data[i-1].push({
                    key: oTable.rows.item(0).cells.item(j).innerHTML, value: cellVal, format: "text"
                })

            }
        }

        if (!invalidFound) {
            dialog.close(data);
        }
    }

    return (
        <div>
            <ModalContent>
                <table id="edit-table-dialog" className="table">
                    {build()}
                </table>
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-brown" onClick={() => setEditable(!editable)}>{editable ? "Nicht mehr editieren" : "Editieren"}</button>
                <button className="button-standard button-green" onClick={() => submitButtonClicked()}>Abschicken</button>
            </ModalFooter>
        </div>
    )
}