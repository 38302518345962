/**
 * Converts a promise to a array
 * @param promise
 * @return {*[]}
 */
export function convertPromiseToArray(promise) {
    let list = [];
    if (!promise) {
        return list;
    }

    for (const key in promise) {
        list.push({key: key, value: promise[key].value});
    }
    return list;
}

