import React from "react";
import {CustomDialog} from "react-st-modal";
import {EditTableDialogComponent} from "../edittabledialogcomponent/EditTableDialogComponent";
import readXlsxFile from "read-excel-file";

export const FormImportComponent = ({submitForm, headerList}) => {
    const importButtonClicked = async () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = ".xlsx, .csv"
        let file;

        input.onchange = e => {
            file = e.target.files[0];
            processImport(file);
        }

        input.click();
    }

    const processImport = async (file) => {
        const rows = await readXlsxFile(file);

        const result = await CustomDialog(<EditTableDialogComponent rows={rows} headerList={headerList}/>, {
            title: 'Daten bearbeiten',
            showCloseIcon: true,
            isCanClose: false,
        });

        if (result !== undefined) {
            submitForm(result);
        }
    }

    return (
        <>
            <button className="button-standard button-brown float-right" onClick={() => importButtonClicked()}>Import</button>
        </>
    )
}