import * as libCrypto from "@waves/ts-lib-crypto";
import {create} from "@waves/node-api-js";
import {broadcast, data, invokeScript, nodeInteraction, transfer} from "@waves/waves-transactions";
import {Alert, CustomDialog} from "react-st-modal";
import React from "react";
import {CompletedTransactionDialogComponent} from "../../components/completedtransactiondialogcomponent/CompletedTransactionDialogComponent";

const NODE_URL = 'https://nodes-testnet.wavesnodes.com';
const USED_NET = 'T';

/**
 * Get the AccountBalance of the address
 * @param address address address of the account
 * @returns {Promise<IBalanceDetails<string | number>>}
 */
export async function getAccountBalance(address) {
    return await nodeInteraction.balanceDetails(address, NODE_URL);
}

/**
 * Get the Account Data from the address, contains [key, type, value]
 * @param address address address of the account
 * @returns {Promise<Record<string, (IBooleanData & {key: string}) | (IIntegerData<string | number> & {key: string}) | (IStringData & {key: string}) | (IBinaryData & {key: string})>>}
 */
export async function getAccountDataList(address) {
    return await nodeInteraction.accountData(address, NODE_URL);
}

/**
 * Get all the Transactions from the address, contains
 * id, type, senderPublicKey, sender, timestamp, data[], height, fee, ...
 * @param address address of the account
 * @returns {Promise<Array<TTransactionFromAPI<TLong>>>}
 */
export async function getTransactionList(address) {
    const api = create(NODE_URL);
    return await api.transactions.fetchTransactions(address, 100); // todo determine the right limit
}

export async function sendTransaction(address, privateKey) {
    const signedTranserTx = transfer({
        amount: 1,
        recipient: address,
        attachment: "iSeL5G8XkgGY6noHTFZehnhQxw8Vf3qmdrGpb1"
    }, {privateKey: privateKey});

    broadcast(signedTranserTx, NODE_URL).then(resp => console.log(resp));
}

/**
 * Create and sign data transaction
 * @param jsonData
 * @param privateKey
 * @return {Promise<boolean>}
 */
export async function sendDataTransaction(jsonData, privateKey) {
    const signedDataTx = data({
        data: jsonData,
        chainId: 84
    }, {privateKey: privateKey});

    try {
        const resp = await broadcast(signedDataTx, NODE_URL)
        await CustomDialog(<CompletedTransactionDialogComponent response={resp}/>, {
            title: 'Trnansaktion',
            showCloseIcon: true,
            isCanClose: false,
        });
        return true;
    } catch (e) {
        await Alert(e.message, 'Transaktion fehlgeschlagen');
        return false;
    }
}

/**
 *
 * @param dAppAddress
 * @param privateKey
 * @param scriptName
 * @return {Promise<void>}
 */
export async function scriptInvocation(dAppAddress, privateKey, scriptName) {
    const invokeTX = invokeScript({
        fee: 500000,
        dApp: dAppAddress,
        chainId: 84,
        call: {function: scriptName},
        payment: null
    }, {privateKey: privateKey})

    return await broadcast(invokeTX, NODE_URL);
}

/**
 * https://www.npmjs.com/package/@waves/waves-api
 * @return {Promise<{seed: string} & {privateKey, address: string, publicKey: string}>}
 */
export async function createWAVESAccount() {
    const seed = libCrypto.randomSeed();
    const privateKeyPublicKeyAddress = getPrivateKeyPublicKeyAndAddressFromSeed(seed);

    return Object.assign({seed: seed}, privateKeyPublicKeyAddress);
}

/**
 * Generating the address from the publicKey
 * @param publicKey publicKey from the account you wish to get the address
 * @return {string}
 */
export function getAddressFromPublicKey(publicKey) {
    return libCrypto.address({publicKey: publicKey}, USED_NET);
}

/**
 * Generate the PublicKey & Address from the PrivateKey
 * @param privateKey
 * @returns {{privateKey, address: string, publicKey: string}}
 */
export function getPublicKeyAndAddressFromPrivateKey(privateKey) {
    const publicKey = libCrypto.publicKey({privateKey: privateKey})
    const address = libCrypto.address({publicKey: publicKey}, USED_NET);
    return {
        privateKey: privateKey,
        publicKey: publicKey,
        address: address
    };
}

/**
 * Generate the PrivateKey, PublicKey & Address from the Seed
 * @param seed
 * @returns {{privateKey, address: string, publicKey: string}}
 */
export function getPrivateKeyPublicKeyAndAddressFromSeed(seed) {
    const privateKey = libCrypto.privateKey(seed);
    return getPublicKeyAndAddressFromPrivateKey(privateKey)
}

/**
 * Check if an address exist
 * @param address
 * @returns {boolean} true if the address exist, otherwise false
 * @todo validate address function
 */
export async function validateAddress(address) {
    return true;
}

/**
 * Converts the Waves Value to an Euro Value
 * @param waves
 * @returns {number}
 * @todo api call
 */
export function wavesToEuro(waves) {
    // Get the current euro value for one wave
    //const euro = wavesPrice.wavesPrice;
    return waves // * euro;
}