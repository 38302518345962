import {ModalContent, ModalFooter, useDialog} from "react-st-modal";
import React, {useEffect, useRef, useState} from "react";

export const ChooseFileNameExtensionDialogComponent = ({data, fileName}) => {
    const dialog = useDialog();
    const [formatValue, setFormatValue] = useState("");
    const inputValue = useRef("");

    const build = () => {
        const arr = [];

        arr.push(<h3>Dateiform</h3>)
        for (const val of data) {
            arr.push(<input type="radio" name="choice" value={val} onClick={event => setFormatValue(event.target.value)}/>)
            arr.push(<> {val} </>);
            arr.push(<br/>)
        }

        arr.push(<h3>Dateiname</h3>)
        arr.push(<p>Feld leer lassen für Standardnamen.</p>)
        arr.push(<input className="full-width" type="text" placeholder={fileName} onChange={event => inputValue.current = event.target.value}/>)

        return arr;
    }

    const buttonClicked = () => {
        if (inputValue.current.length === 0) {
            inputValue.current = fileName;
        }

        if (formatValue.length === 0) {
            alert("Bitte wählen Sie eine Dateiform aus.")
        } else {
            dialog.close({format: formatValue, fileName: inputValue.current});
        }
    }

    return (
        <div>
            <ModalContent>
                {build()}
            </ModalContent>

            <ModalFooter>
                <button className="button-standard button-green" onClick={() => buttonClicked()}>Abschicken</button>
            </ModalFooter>
        </div>
    )
}