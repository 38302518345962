/**
 * Init WavesKeeper
 */
export function wavesKeeperInit() {
    if(typeof WavesKeeper !== 'undefined') {
        // eslint-disable-next-line no-undef
        return WavesKeeper.auth({name: 'Waves Keeper', data: 'Waves Keeper', icon: 'https://docs.wavesplatform.com/theme/brand-logo/waves-docs-logo.png'});
    } else {
        alert("Please install WavesKeeper");
    }
}

export async function wavesKeeperGetPublicKey() {
    const keeperPublicState = await wavesKeeperGetPublicState();
    return keeperPublicState["account"].publicKey;
}

/**
 * Get the PublicState from the WavesKeeper, contains
 * initialized, locked, account[name, publicKey, address, networkCode, balance[available, leasedOut], network[], ...]
 * @returns {Promise<WavesKeeper.IPublicStateResponse>}
 */
export async function wavesKeeperGetPublicState() {
    // eslint-disable-next-line no-undef
    return WavesKeeper.publicState();
}

/**
 * Send data as json file to the blockchain, getting the needed information from the WavesKeeper
 * @param jsonData
 * @returns {Promise<void>}
 */
export async function wavesKeeperSendDataTransaction(jsonData) {
    // todo check json data before broadcasting
    // eslint-disable-next-line no-undef
    WavesKeeper.signAndPublishTransaction({
        type: 12,
        data: {
            data: jsonData,
            fee: {
                "tokens": "0.01",
                "assetId": "WAVES"
            }
        }
    }).then((tx) => {
        console.log("Hurray! I've saved data!!!");
    }).catch((error) => {
        console.error("Something went wrong", error);
    });
}