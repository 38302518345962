import {chooseDecryptedValueOrValue, chooseEncryptedValueOrValue} from "./conversions/ConversionDataKey";

/**
 * Generates a array with key, value (encrypted or unencrypted, will be selected automatic) and add it to the array
 * @param key
 * @param keyValue
 * @param arr
 */
export const constructCardArray = (key, keyValue, arr) => {
    if (keyValue === undefined || arr === undefined || arr === null)
        return;

    const array = {};
    array[key] = chooseEncryptedValueOrValue(keyValue);
    Object.assign(arr, array);
}

/**
 * Generates a array with key, append two values and add it to the array
 * @param key
 * @param keyValue
 * @param keyValue2
 * @param arr
 */
export const constructCardArrayTwoValues = (key, keyValue, keyValue2, arr) => {
    if (keyValue === undefined || keyValue2 === undefined || arr === undefined || arr === null)
        return;

    const array = {};
    array[key] =
        (chooseDecryptedValueOrValue(keyValue))
        + " " +
        (chooseDecryptedValueOrValue(keyValue2)) ;
    Object.assign(arr, array);
}

/**
 * Generates a array with key, value and add it to the array
 * @param key
 * @param value
 * @param arr
 */
export const constructCardArrayFromSingleValue = (key, value, arr) => {
    if (arr === undefined || arr === null)
        return;

    const array = {};
    if (typeof value === "object") {
        array[key] = chooseDecryptedValueOrValue(value);
    } else {
        array[key] = value;
    }

    Object.assign(arr, array);
}