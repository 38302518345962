import React, {useEffect, useState} from "react";
import {CustomDialog} from "react-st-modal";
import userImage from "../../ressources/icons/user.png"
import connectionImage from "../../ressources/icons/people.png"
import {buildEjotFormArray, buildEjotSupplierDataHeaderList} from "../../builder/EjotSupplierBuilder";
//import {Utils} from "../../@shared/extern/piel_js/Utils";
import {getCurrentDate} from "../../@shared/util/conversions/ConversionsTime";
import {chooseDecryptedValueOrValue} from "../../@shared/util/conversions/ConversionDataKey";
import {ComplexSelectDialogComponent} from "../../@shared/components/complexselectdialogcomponent/ComplexSelectDialogComponent";
import {FormComponent} from "../../@shared/components/formcomponent/FormComponent";
import {MainPageComponent} from "../../@shared/components/mainpagecomponent/MainPageComponent";
import {AccountInformationComponent} from "../../@shared/components/accountinformationcomponent/AccountInformationComponent";
import {ConnectionComponent} from "../../@shared/components/connectioncomponent/ConnectionComponent";
import {EjotSupplierDataComponent} from "../../components/ejotdatacomponent/EjotSupplierDataComponent";
import {TransactionsComponent} from "../../@shared/components/transactionscomponent/TransactionsComponent";
var Utils = require("../../@shared/extern/piel_js/Utils")

export const EjotSupplierPageContentComponent = ({activePage, accountInformation, connectionEjotList, accountDataList, transactionList,
                                                     uploadDataToBlockchainWithPrivateKey, uploadDataToBlockchainWithSharedKey, deleteDataFromBlockchain}) => {
    const [formArray, setFormArray] = useState(null);

    useEffect(() => {
        // Form Array
        setFormArray(buildEjotFormArray());
    },[]);

    /**
     * Creates the form blockchain key that will be uploaded to the blockchain
     * @param key corresponding key
     * @param pubKey publicKey from the account
     * @param identifier
     * @return {string} currentDate_pubKey_key
     */
    function createFormBlockchainKey(key, pubKey, identifier) {
        const utils = new Utils()
        // Hash the identifier
        const hash = utils.toSha256(identifier);

        return getCurrentDate() + "_" + hash + "_" + pubKey + "_" + key; // todo timestamp to date
    }

    const submitForm = async (formData) => {
        // formats the data key
        const formattedFormData = [];
        for (let i = 0; i < formData.length; i++) {
            // Find the "BestellNr"
            // todo what is if the BestellNr is empty?
            let identifier;
            for (const key in formData[i]) {
                if (formData[i][key].key === "BestellNr") {
                    identifier = formData[i][key].value;
                    break;
                }
            }

            // Change the key
            for (const key in formData[i]) {
                formattedFormData.push(
                    {key: createFormBlockchainKey(formData[i][key].key, accountInformation.publicKey, identifier), value: formData[i][key].value}
                )
            }
        }

        if (uploadDataToBlockchainWithSharedKey !== undefined) {
            // requests the user for the recipient's public key
            const connections = {};
            for (let i = 0; i < connectionEjotList.length; i++) {
                // convert the object to a simple array
                connections[connectionEjotList[i].keyKey] = chooseDecryptedValueOrValue(connectionEjotList[i]);
            }

            const receiverPubKey = await CustomDialog(<ComplexSelectDialogComponent data={connections}/>, {
                title: 'Verknüpfung auswählen',
                showCloseIcon: true,
                isCanClose: false,
            });

            if (receiverPubKey !== undefined) {
                // sends the data to the blockchain
                uploadDataToBlockchainWithSharedKey(formattedFormData, receiverPubKey);
            }
        } else if (uploadDataToBlockchainWithPrivateKey !== undefined) {
            uploadDataToBlockchainWithPrivateKey(formattedFormData);
        }
    }

    const selectPage = () => {
        if (activePage === "form") {
            return <FormComponent formContentArray={formArray}
                                  submitForm={submitForm}
                                  headerList={buildEjotSupplierDataHeaderList()}/>;
        } else if (activePage === "mainpage") {
            return (
                <MainPageComponent
                    content={[
                        {"title": "Kontoinformationen", "identifier": "accountInformation", "defaultImage": userImage,
                            "component": <AccountInformationComponent accountInformation={accountInformation}/>},
                        {"title": "Verknüpfung", "identifier": "connections", "defaultImage": connectionImage,
                            "component": <ConnectionComponent connectionsList={connectionEjotList}
                                                              uploadDataToBlockchain={uploadDataToBlockchainWithPrivateKey}
                                                              deleteDataFromBlockchain={deleteDataFromBlockchain}
                                                              maxConnections={1}
                                                              keyPrefix={"ejot"}/>},
                    ]}
                />
            )
        } else if (activePage === "data") {
            return <EjotSupplierDataComponent supplierDataList={accountDataList}
                                      connectionsList={connectionEjotList}
                                      uploadDataToBlockchainWithSharedKey={uploadDataToBlockchainWithSharedKey}
                                      headerList={buildEjotSupplierDataHeaderList()}

            />
        } else if (activePage === "transactions") {
            return <TransactionsComponent transactionList={transactionList}/>
        }
    }

    return (
        <div className="flex full-height full-width main-content">
            {selectPage()}
        </div>
    )
}