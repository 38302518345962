import React, {useEffect, useState} from "react";
import ejotFactoryImage from "../../ressources/images/Tambach.jpg"
import ejotBlockchain1 from "../../ressources/images/Blockchain1.jpg"
import ejotBlockchain2 from "../../ressources/images/Blockchain2.jpg"
import ejotDraht1 from "../../ressources/images/Draht1.jpg"
import ejotDraht2 from "../../ressources/images/Draht2.jpg"
import ejotSchrauben1 from "../../ressources/images/Schrauben1.jpg"
import ejotSchrauben2 from "../../ressources/images/Schrauben2.jpg"
import ejotIcon from "../../ressources/images/ejotLogo.png"
import bc4scImage from "../../ressources/images/20201208_BC4SC_LOGOtransparent_v1.png"

import {FooterComponent} from "../../@shared/components/footercomponent/FooterComponent";
import {EjotPageContentComponent} from "./EjotPageContentComponent";
import {HeaderComponent} from "../../@shared/components/headercomponent/HeaderComponent";
import {ContentImageComponent} from "../../@shared/components/contentimagecomponent/ContentImageComponent";
import {DataSummaryComponent} from "../../@shared/components/datasummarycomponent/DataSummaryComponent";
import {Confirm} from "react-st-modal";
import * as EjotBlockchainAPI from "../../api/EjotBlockchainAPI";

export const EjotPageComponent =  ({WavesSelectorAPI, userLoggedOut}) => {
    // Waves
    const [accountInformation, setAccountInformation] = useState();
    const [connectionSuppliersList, setConnectionSuppliersList] = useState([]);
    const [suppliersData, setSuppliersData] = useState([]);
    const [transactionList, setTransactionList] = useState([]);

    // Content & Image
    const [posterImage, setPosterImage] = useState(ejotFactoryImage);
    const [posterText, setPosterText] = useState("Startseite");
    const [activePage, setActivePage] = useState("mainpage");

    // Data Summary
    const [dataSummaryDataList, setDataSummaryDataList] = useState([]);

    // componentDidMount
    useEffect(async () => {
        await updateAccountInformation();
    }, [WavesSelectorAPI]);

    const updateAccountInformation = async () => {
        const data = await EjotBlockchainAPI.getAccountData(WavesSelectorAPI);
        console.log(data)

        // Data
        await setTransactionList(data.transactionList)
        await setAccountInformation(data.accountInformation);
        await setSuppliersData(data.suppliersData);
        await setConnectionSuppliersList(data.connectionSupplierList);
    }

    /**
     * Change the displayed content, also set the poser text and poser image
     * @param page
     */
    const updateActiveContentPage = (page) => {
        if (page === "mainpage") {
            setPosterText("Startseite");
            setPosterImage(ejotBlockchain1);
        } else if (page === "data") {
            setPosterText("Daten");
            setPosterImage(ejotBlockchain2);
        }
        setActivePage(page);
    }

    /**
     * encrypt the data with the sharedkey and uploads it to the blockchain
     * @param data
     * @param publicKeyReceiver
     * @return {Promise<void>}
     */
    const uploadDataToBlockchainWithSharedKey = async (data, publicKeyReceiver) => {
        const result = await Confirm('Wollen Sie die Daten direkt speichern oder diese zwischenspeichern?',
            'Speichern/Zwischenspeichern', "Speichern", "Zwischenspeichern");

        const encryptedData = await WavesSelectorAPI.encryptDataWithSharedKey(data, publicKeyReceiver);
        if (result) {
            await WavesSelectorAPI.uploadDataToWavesBlockchain(encryptedData);
        } else {
            await addDataToDataSummaryDataList(encryptedData, "add");
        }
    }

    /**
     * encrypt the data with the privatekey and uploads it to the blockchain
     * @param data
     * @return {Promise<void>}
     */
    const uploadDataToBlockchainWithPrivateKey = async (data) => {
        const result = await Confirm('Wollen Sie die Daten direkt speichern oder diese zwischenspeichern?',
            'Speichern/Zwischenspeichern', "Speichern", "Zwischenspeichern");

        const encryptedData = await WavesSelectorAPI.encryptDataWithPrivateKey(data);
        if (result) {
            await WavesSelectorAPI.uploadDataToWavesBlockchain(encryptedData);
        } else {
            await addDataToDataSummaryDataList(encryptedData, "add");
        }
    }

    /**
     * Deletes the data [] from the blockchain
     * https://github.com/wavesplatform/waves-documentation/blob/master/en/blockchain/transaction-type/data-transaction.md
     * The key is being deleted when its type and value are not specified.
     * @param data
     * @return {Promise<void>}
     */
    const deleteDataFromBlockchain = async (data) => {
        const result = await Confirm('Wollen Sie die Daten direkt speichern oder diese zwischenspeichern?',
            'Speichern/Zwischenspeichern', "Speichern", "Zwischenspeichern");

        const deletedData = await WavesSelectorAPI.deleteDataFromBlockchain(data);
        if (result) {
            await WavesSelectorAPI.uploadDataToWavesBlockchain(deletedData);
        } else {
            await addDataToDataSummaryDataList(deletedData, "delete");
        }
    }

    /**
     * Uploads the data to the blockchain, without the data summary confirmation
     * @param data
     * @return {Promise<boolean>}
     */
    const uploadDataToBlockchain = async (data) => {
        // todo process return value
        await WavesSelectorAPI.uploadDataToWavesBlockchain(data)
        return true;
    }

    /**
     * Adds the data to the summary array
     * @param data
     * @param origin
     * @return {Promise<void>}
     * @todo this only saves the "data" and ignores the previous dataSummaryDataList
     */
    const addDataToDataSummaryDataList = async (data, origin = "unknown") => {
        const arr = [...dataSummaryDataList];
        for (let i = 0; i < data.length; i++) {
            data[i].next = origin;
            arr[data[i].key] = data[i];
        }
        await setDataSummaryDataList(arr);
    }

    return (
        <div id="app">
            <div className="wrapper">
                <HeaderComponent
                    menu={{"mainpage": "Startseite", "data": "Daten"}}
                    updateActiveContentPage={updateActiveContentPage}
                    userLoggedOut={userLoggedOut}
                    icon={ejotIcon}
                />

                <ContentImageComponent
                    image={posterImage}
                    imageText={posterText}
                />

                <DataSummaryComponent
                    data={dataSummaryDataList}
                    uploadDataToBlockchain={uploadDataToBlockchain}
                />

                <div className="main">
                    <EjotPageContentComponent
                        activePage={activePage}

                        accountInformation={accountInformation}
                        connectionSuppliersList={connectionSuppliersList}
                        suppliersData={suppliersData}
                        transactionList={transactionList}

                        uploadDataToBlockchainWithSharedKey={uploadDataToBlockchainWithSharedKey}
                        uploadDataToBlockchainWithPrivateKey={uploadDataToBlockchainWithPrivateKey}
                        deleteDataFromBlockchain={deleteDataFromBlockchain}
                    />
                </div>

                <FooterComponent displayText={""} displayImage={bc4scImage}/>
            </div>
        </div>
    )
}