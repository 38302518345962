import {AccountPublicInformation} from "../@shared/api/waves/AccountPublicInformation";
import {getAccountDataList, getAddressFromPublicKey} from "../@shared/api/waves/WavesAPI";
import SharedKeyEncryption from "../@shared/extern/piel_js/sharedKeyEncryption";

export async function getAccountData(WavesSelectorAPI) {
    const returnObject = {};

    const accountInformation = new AccountPublicInformation(await WavesSelectorAPI.getAddress(), WavesSelectorAPI.publicKey);
    await accountInformation.updateAllData();

    const accountDataList = accountInformation.accountDataList;
    const transactionList = accountInformation.transactionList;
    const filteredAccountDataList = [];

    // Connections
    const connectionEjotIdentifier = "connection_ejot_";
    const connectionEjotArr = [];

    // AccountDataList
    for (const key in accountDataList) {
        // Connection
        if (accountDataList[key].key.startsWith(connectionEjotIdentifier)) {
            // Key: connection_Ejot
            accountDataList[key]["keyKey"] = accountDataList[key].key.substr(connectionEjotIdentifier.length); // Ejot
            connectionEjotArr.push(accountDataList[key]);
        }  else if (accountDataList[key].key.startsWith("connection_")) {
            continue; // todo remove after refactor
        }
        // Others/Data
        else {
            // Key: 20210722_043a718774c572bd8a25adbeb1bfcd5c0256ae11cecf9f9c3f925d0e52beaf89_HrgRdsxrSvtQMBATpEn8knXiYJbRX1jHzN44ccp8NXRB_ArtikelNr
            const keySplit = accountDataList[key].key.split("_");
            const keyIdentifier =  keySplit[0] + "_" + keySplit[1] + "_" + keySplit[2];
            const keyKey = keySplit[3];
            const keyPubKey = keySplit[2];

            if (filteredAccountDataList[keyIdentifier] === undefined) {
                filteredAccountDataList[keyIdentifier] = [];
                filteredAccountDataList[keyIdentifier].key = keyIdentifier;
            }

            filteredAccountDataList[keyIdentifier][keyKey] = {
                key: accountDataList[key].key, value: accountDataList[key].value,
                keyPubKey: keyPubKey, keyKey: keyKey, keyIdentifier: keyIdentifier, decryptedValue: null,
                origin: "self", newValue: ""
            }
        }
    }

    // Decrypt Ejot Connection
    const decryptedConnectionEjotArr = await WavesSelectorAPI.decryptDataWithPrivateKey(connectionEjotArr);

    // Get the data from the connection
    if (decryptedConnectionEjotArr.length === 1) {
        const address = getAddressFromPublicKey(decryptedConnectionEjotArr[0].decryptedValue);
        const ejotEncryptedAccountDataList = await getAccountDataList(address);

        for (const key in ejotEncryptedAccountDataList) {
            // Filter
            if (ejotEncryptedAccountDataList[key].key.startsWith("connection_"))
                continue;

            // Key: 20210722_043a718774c572bd8a25adbeb1bfcd5c0256ae11cecf9f9c3f925d0e52beaf89_HrgRdsxrSvtQMBATpEn8knXiYJbRX1jHzN44ccp8NXRB_ArtikelNr
            const keySplit = ejotEncryptedAccountDataList[key].key.split("_");
            const keyIdentifier =  keySplit[0] + "_" + keySplit[1] + "_" + keySplit[2];
            const keyKey = keySplit[3];
            const keyPubKey = keySplit[2];

            // Filter all the data that are not for the supplier (wrong publicKey in key)
            if (keyPubKey !== WavesSelectorAPI.publicKey)
                continue;

            if (filteredAccountDataList[keyIdentifier] === undefined) {
                filteredAccountDataList[keyIdentifier] = [];
                filteredAccountDataList[keyIdentifier].key = keyIdentifier;
            }

            filteredAccountDataList[keyIdentifier][keyKey] = {
                key: ejotEncryptedAccountDataList[key].key, value: ejotEncryptedAccountDataList[key].value,
                keyPubKey: keyPubKey, keyKey: keyKey, keyIdentifier: keyIdentifier, decryptedValue: null,
                origin: "extern"
            }
        }
    }

    // Decrypt Data
    if (decryptedConnectionEjotArr[0] !== undefined) {
        const decryptedFilteredAccountDataList = await WavesSelectorAPI.decryptEjotDataWithSharedKey(filteredAccountDataList, decryptedConnectionEjotArr[0].decryptedValue);
        returnObject.accountDataList = decryptedFilteredAccountDataList;

        // TransactionList
        // todo this is basically the same as above
        const sharedKeyEncryption = new SharedKeyEncryption(decryptedConnectionEjotArr[0].decryptedValue, WavesSelectorAPI.privateKey);
        for (let i = 0; i < transactionList.length; i++) {
            if (transactionList[i].type === 12) {
                for (const key in transactionList[i].data) {
                    if (transactionList[i].data[key].key.startsWith("connection_")) {
                        transactionList[i].data[key].decryptedValue = await WavesSelectorAPI.decryptMessageWithPrivateKey(transactionList[i].data[key].value);
                    } else {
                        transactionList[i].data[key].decryptedValue = await WavesSelectorAPI.decryptMessageWithSharedKey(transactionList[i].data[key].value, sharedKeyEncryption)
                    }
                }
            }
        }
    }

    returnObject.accountInformation = accountInformation;
    returnObject.connectionEjotList = decryptedConnectionEjotArr;
    returnObject.transactionList = transactionList;

    return returnObject;
}