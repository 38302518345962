import React, {useState} from 'react';
import {getPrivateKeyPublicKeyAndAddressFromSeed} from "../../../../api/waves/WavesAPI";

export const LoginSeedComponent = ({login}) => {
    const [seed, setSeed] = useState("");

    const seedInputChanged = (value) => {
        setSeed(value.target.value);
    }

    const loginWithSeed = (event) => {
        event.preventDefault();
        if (seed.length === 0) {
            alert("Der seed ist leer.");
        } else if (seed.length < 12) {
            alert("Das Seed ist zu kurz. Ein Seed besteht aus mindestens 12 Zeichen.");
        } else {
            const keyPair = getPrivateKeyPublicKeyAndAddressFromSeed(seed);
            login(keyPair);
        }
    }

    return (
        <div className="login-selection">
            <form onSubmit={loginWithSeed}>
                <label className="label">Seed</label>
                <input type="text" className="input" value={seed} onChange={seedInputChanged}/>
                <button className="login-screen-button">Anmelden mit Seed</button>
            </form>
        </div>
    );
}


