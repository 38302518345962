const crypto = require('crypto');
const { invokeScript, data, broadcast } = require('@waves/waves-transactions');
const request = require('request')


/**
* Some useful utils
* 
*
*/

class Utils {

    constructor() { }

    /**
* generates a SHA256 Hash out of a string message
* 
* @param {String} msg  String message to hash
* @return {String}  A SHA256 string in Hexadecimal 
*/
    toSha256(msg) {
        return crypto.createHash("sha256")
            .update(msg.toString())
            .digest("hex");
    }


    /**
* Get current date in (mmddyyyy) format
* 
* @return {String}  Current date 
*  
*/
    getCurrentDate() {

        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();

        return day.toString() + month.toString() + year.toString()
    }


    /**
   * Execute a data transaction to Piel Waves Account data storage
   * 
   * @param {Array} dataArray - Json array to be sent
   * @param {String} seed - Json array to be sent
   * @return {void} 
   */
    storeDataToWavesAccount(seed, dataArray) {
        return new Promise((resolve, reject) => {

            const nodeUrl = 'https://nodes-testnet.wavesnodes.com';

            //const pielSeed = 'feel deposit recipe assume crew sniff soul crunch light canal artefact stomach then wolf ready';
            const dataTx = data({ data: dataArray }, seed); // Create and sign data transaction

            try {
                let tx = broadcast(dataTx, nodeUrl)
                resolve(tx)
            } catch (error) {
                reject(error)

            }

        })

    }




    /**
* Get data from Piel Waves account using Key 
* 
* @param {String} key - key of the data to get  
* @return {Promise} Promise: resolves when data are retrieved
* 
*/
    getData(key, addr) {

        const baseurl = 'https://nodes-testnet.wavesnodes.com'

        const URIquery = baseurl + '/addresses/data/' + addr + '?key=' + encodeURIComponent(key)

        return new Promise((resolve, reject) => {

            request(URIquery, function (error, response, body) {

                if (response && response.statusCode === 200 && JSON.parse(body).length != 0) {

                    resolve(JSON.parse(body)[0].value)

                } else if (response && response.statusCode === 200 && JSON.parse(body).length === 0) {

                    reject({ 'error': 304, 'message': 'no data for this key' })

                } else {
                    reject({ 'error': error })
                }

            });

        })

    }


     /**
* Invoke script in a Smart contract (dApp)
* 
* @param {String} adr - address of the dApp  
* @return {void}
* 
*/
    async invoke(adr, seed) {
        const nodeUrl = 'https://nodes-testnet.wavesnodes.com';

        try {
            const tx1 = await broadcast(invokeScript({ dApp: adr, call: { function: "perfomOrder", args: [{ "type": "string", "value": this.getCurrentDate() }] }, payment: [], chainId: 'TESTNET' }, seed), nodeUrl)
            const tx2 = await broadcast(invokeScript({ dApp: adr, call: { function: "perfomOrder2", args: [{ "type": "string", "value": this.getCurrentDate() }] }, payment: [], chainId: 'TESTNET' }, seed), nodeUrl)

        } catch (error) {
            console.log(error);

        }

    }



}

export default Utils
//module.exports = Utils

