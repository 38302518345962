import React, {useEffect, useState} from 'react';
import "./AccountInformationComponent.css"
import {convertCurrency} from "../../util/conversions/ConversionsCurrency";
import {CardComponent} from "../cardcomponent/CardComponent";
import {constructCardArrayFromSingleValue} from "../cardcomponent/CardArrayGenerator";
import {chooseDecryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

/**
 * displays the account information
 * @param accountInformation object with the blockchain account information
 * @returns {JSX.Element}
 */
export const AccountInformationComponent = ({accountInformation}) => {
    /**
     * generates all the cards for the account information
     * @returns {*[]} array
     */
    const generateAccountInformation = () => {
        // TODO: add clickable link to blockchain website
        const cardArray = [];

        // Balance
        const balanceArr = [];
        constructCardArrayFromSingleValue("Guthaben", "Guthaben", convertCurrency(accountInformation.availableRegularBalance), "", balanceArr);

        cardArray.push(<CardComponent title={"Verfügbares Guthaben"} data={balanceArr}/>);
        cardArray.push(<br/>)

        // Address
        const addressArr = [];
        constructCardArrayFromSingleValue("Adresse", "Adresse", accountInformation.address, "", addressArr);
        constructCardArrayFromSingleValue("PubKey", "Public Key", accountInformation.publicKey, "", addressArr);

        cardArray.push(<CardComponent title={"Blockchain"} data={addressArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * Creates a placeholder text when no data are available
     * @returns {JSX.Element}
     */
    const createNoDataPlaceholder = () => {
        return (
            <div className="placeholder placeholder-primary">
                <h3>Es sind keine Daten vorhanden.</h3>
            </div>
        )
    }

    /**
     * Creates a placeholder text when no data are available
     * @returns {JSX.Element}
     */
    const createErrorPlaceholder = () => {
        return (
            <div className="placeholder placeholder-primary">
                <h3>Fehler beim Laden der Accountdaten.</h3>
            </div>
        )
    }

    return (
        <div className="account-information">
            {accountInformation === undefined || accountInformation === null ? createErrorPlaceholder() : generateAccountInformation()}
        </div>
    )
}