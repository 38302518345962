import React, {useState} from 'react';
import {getPublicKeyAndAddressFromPrivateKey} from "../../../../api/waves/WavesAPI";

export const LoginPrivateKeyComponent = ({login}) => {
    const [privateKey, setPrivateKey] = useState("");

    const privateKeyInputChanged = (value) => {
        setPrivateKey(value.target.value)
    }

    const loginWithPrivateKey = (event) => {
        event.preventDefault();
        if (privateKey.length === 0) {
            alert("Der Private Key ist leer.");
        } else if (privateKey.length < 44) {
            alert("Der Private Key ist zu kurz. Ein Private Key besteht aus mindestens 44 Zeichen.");
        } else {
            const keyPair = getPublicKeyAndAddressFromPrivateKey(privateKey)
            login(keyPair);
        }
    }

    return (
        <div className="login-selection">
            <form onSubmit={loginWithPrivateKey}>
                <label className="label">Privater Schlüssel (Private Key)</label>
                <input type="text" className="input" value={privateKey} onChange={privateKeyInputChanged}/>
                <button className="login-screen-button">Anmelden mit privatem Schlüssel (Private Key)</button>
            </form>

        </div>
    );

}