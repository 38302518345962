import React from 'react';
import "./FooterComponent.css";

/**
 * footer
 * @return {JSX.Element}
 * @constructor
 */
export const FooterComponent = ({displayText, displayImage}) => {
    const build = () => {
        if (displayText !== undefined && displayText !== null && displayText.length > 0) {
            return displayText;
        } else {

        }
    }

    return (
        <div className="footer">
            <div className="footer-text text-left">
                <img style={{maxWidth: "40%", maxHeight: "40%", paddingLeft: "251px"}} src={displayImage} alt="FooterImage"/>
            </div>
        </div>
    )
}