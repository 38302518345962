import React from "react";
import {createWAVESAccount} from "../../../../api/waves/WavesAPI";

export const LoginCreateAccountComponent = ({login}) => {
    const buttonClicked = async () => {
        const account = await createWAVESAccount();
        alert("Ein neuer Account wurde angelegt mit den folgenden Daten: \n\n" +
            "Address: " + account.address + "\n" +
            "PublicKey: " + account.publicKey + "\n" +
            "PrivateKey: " + account.privateKey + "\n" +
            "Seed: " + account.seed + "\n\n" +
            "Speichere diese gut ab!");

        login({privateKey: account.privateKey, publicKey: account.publicKey});
    }

    return (
        <div className="login-selection">
            <button type="button" className="login-screen-button"
                onClick={buttonClicked}>
                Account erstellen
            </button>
        </div>
    )
}