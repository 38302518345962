import React, {useState} from 'react'
import "./LoginFormComponent.css";
import {LoginFormTitleComponent} from "./LoginFormTitleComponent";
import {LoginFormImageComponent} from "./LoginFormImageComponent";
import {LoginFormSelectionComponent} from "./LoginFormSelectionComponent";
import {getPublicKeyAndAddressFromPrivateKey} from "../../../api/waves/WavesAPI";

export const LoginFormComponent = ({loginKeyPair, loginWavesKeeper, loginWavesSigner, randomImage}) => {
    // todo temp
    const buttonClicked = (privateKey) => {
        const keyPair = getPublicKeyAndAddressFromPrivateKey(privateKey)
        loginKeyPair(keyPair);
    }

    return (
        <div className="login-form">
            <div className="login-form-left">
                <LoginFormImageComponent
                    randomImage={randomImage}
                />
            </div>

            <div className="login-form-right">
                <LoginFormTitleComponent/>
                <LoginFormSelectionComponent loginKeyPair={loginKeyPair}
                                             loginWavesKeeper={loginWavesKeeper}
                                             loginWavesSigner={loginWavesSigner}/>
            </div>
        </div>
    )
}