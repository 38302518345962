import React, {useEffect} from "react";
import {correctCardBodyKeyWidth} from "../../stylesheets/card/CardMenuButtonWidthCorrector";
import {convertTimestampToDate} from "../../util/conversions/ConversionsTime";
import {constructCardArrayFromSingleValue} from "../../util/CardArrayGenerator";
import {convertCurrency} from "../../util/conversions/ConversionsCurrency";
import {CardComponent} from "../../stylesheets/card/CardComponent";
import {chooseDecryptedValueOrValue, chooseEncryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

export const TransactionsContentComponent = ({transaction, typeToString}) => {
    /**
     * Adjusts the width to the width of the largest object
     */
    useEffect(() => {
        correctCardBodyKeyWidth();
    }, [transaction])

    /**
     * data transaction
     * @param transaction the transaction
     * @returns {*[]}
     */
    const buildDataTransferTransaction = (transaction) => {
        const cardArray = [];

        let dataTransferArr = {};

        for (const key in transaction.data) {
            constructCardArrayFromSingleValue(transaction.data[key].key, chooseDecryptedValueOrValue(transaction.data[key]), dataTransferArr);
        }

        cardArray.push(<CardComponent title={typeToString(transaction.type)} objectArr={dataTransferArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * asset transfer
     * @param transaction the transaction
     * @returns {*[]}
     */
    const buildAssetTransferTransaction = (transaction) => {
        const cardArray = [];

        let assetTransferArr = {};
        constructCardArrayFromSingleValue("Empfänger", transaction.recipient, assetTransferArr);
        constructCardArrayFromSingleValue("Betrag", convertCurrency(transaction.amount), assetTransferArr);
        constructCardArrayFromSingleValue("Anhang", transaction.attachment.length === 0 ? "kein Anhang" : transaction.attachment, assetTransferArr);

        cardArray.push(<CardComponent title={typeToString(transaction.type)} objectArr={assetTransferArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * unknown type transaction
     * @param transaction the transaction
     * @returns {*[]}
     */
    const buildUnknownTypeTransaction = (transaction) => {
        const cardArray = [];

        let unknownTypeArr = {};
        constructCardArrayFromSingleValue("Transaktionsart wurde noch nicht konfiguriert", null, unknownTypeArr);

        cardArray.push(<CardComponent title={typeToString(transaction.type)} objectArr={unknownTypeArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * script invocation
     * @param transaction the transaction
     * @returns {*[]}
     */
    const buildScriptInvocationTransaction = (transaction) => {
        // TODO: Transfer Sender
        // TODO: When transfers[0] is undefined, change the text
        const cardArray = [];

        let scriptInvocationArr = {};
        constructCardArrayFromSingleValue(transaction.stateChanges.data[0].key, transaction.stateChanges.data[0].value, scriptInvocationArr);

        cardArray.push(<CardComponent title={typeToString(transaction.type)} objectArr={scriptInvocationArr}/>);
        cardArray.push(<br/>)

        let transferArr = {};
        constructCardArrayFromSingleValue("Betrag", convertCurrency(transaction.stateChanges.transfers[0] !== undefined ? transaction.stateChanges.transfers[0].amount : 0), transferArr);

        cardArray.push(<CardComponent title={"Transfer"} objectArr={transferArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * general information about the transaction, such as ID, Type, Status, Time, Fee, Sender
     * @param transaction the transaction
     * @returns {*[]}
     */
    const buildGeneralTransactionInformation = (transaction) => {
        const cardArray = [];

        let generalArr = {};
        constructCardArrayFromSingleValue("ID", transaction.id, generalArr);
        constructCardArrayFromSingleValue("Typ", typeToString(transaction.type), generalArr);
        constructCardArrayFromSingleValue("Status", transaction.applicationStatus === "succeeded" ? "Erfolgt" : "-", generalArr);
        constructCardArrayFromSingleValue("Zeit", convertTimestampToDate(transaction.timestamp), generalArr);
        constructCardArrayFromSingleValue("Gebühr", convertCurrency(transaction.fee), generalArr);
        constructCardArrayFromSingleValue("Absender", transaction.sender, generalArr);

        cardArray.push(<CardComponent title={"Transaktions Informationen"} objectArr={generalArr}/>);
        cardArray.push(<br/>)

        return cardArray;
    }

    /**
     * todo description
      * @param transaction
     * @return {JSX.Element|null}
     */
    const buildTransaction= (transaction) => {
        let individualInformation = {};

        if (transaction === null || transaction === "") {
            return null;
        } else if (transaction.type === 12) {
            individualInformation =  buildDataTransferTransaction(transaction);
        } else if (transaction.type === 4) {
            individualInformation = buildAssetTransferTransaction(transaction);
        } else if (transaction.type === 16) {
            individualInformation =  buildScriptInvocationTransaction(transaction);
        } else {
            individualInformation =  buildUnknownTypeTransaction(transaction);
        }

        return (
            <div>
                {buildGeneralTransactionInformation(transaction)}
                {individualInformation}
            </div>
        )
    }

    /**
     * generates the transaction title in the format: Type (in readable form) | Date
     * @param transaction the transaction
     * @returns {string} title or placeholder text if the transaction is empty
     */
    const buildTransactionTitle = (transaction) => {
        if (transaction.length !== 0) {
            return typeToString(transaction.type) + " | " + convertTimestampToDate(transaction.timestamp);
        } else {
            return "Keine Transaktion ausgewählt.";
        }

    }

    return (
        <div className="transaction-content">

            <div className="mainpage-content-title">
                <h1>{buildTransactionTitle(transaction)}</h1>
            </div>

            {buildTransaction(transaction)}
        </div>
    )
}