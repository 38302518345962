import React, {useEffect, useState} from 'react';
import {HeaderMenuComponent} from "./HeaderMenuComponent";
import {LogoutButtonComponent} from "./LogoutButtonComponent";
import {HeaderIconComponent} from "./HeaderIconComponent";
import "./HeaderComponent.css"

/**
 * the header containing the menu
 * @param updateContentDisplay function
 * @param userLoggedOut function to log the user out
 * @return {JSX.Element}
 */
export const HeaderComponent = ({menu, updateActiveContentPage, userLoggedOut, text, icon}) => {
    return (
        <div className="header overflow-auto">
            <div className="flex header-nav overflow-hidden">
                <HeaderIconComponent text={text} icon={icon}/>
                <HeaderMenuComponent menu={menu}
                                     updateActiveContentPage={updateActiveContentPage}/>
                <LogoutButtonComponent userLoggedOut={userLoggedOut}/>
            </div>
        </div>
    )
}