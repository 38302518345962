const {data, broadcast} = require('@waves/waves-transactions');
//const crypto = require('crypto');
const wavesCrypto = require('@waves/ts-lib-crypto')


/**
 * Class to create a sharedKey encryption
 *
 *
 */
class SharedKeyEncryption {

    constructor(rpublicKey, privateKey) {
        this.privateKey = privateKey
        this.recieverPublicKey = rpublicKey;
        //this.privateKey = wavesCrypto.privateKey(privateKey);
        this.sharedKey = wavesCrypto.sharedKey(this.privateKey, this.recieverPublicKey, 'waves')
    }

    getSharedKey() {
        return this.sharedKey;
    }

    /**
     * Encrypt a message
     *
     * @param {String} msg - message to be encrypted
     * @return {String} cipher text of a message
     *
     */
    encrypt(msg) {
        const encrypted = wavesCrypto.messageEncrypt(this.sharedKey, msg)
        return wavesCrypto.base64Encode(encrypted)
    }

    /**
     * Decrypt a message
     *
     * @param {String} msg - message to be decrypted
     * @return {String} plain text of message
     *
     */
    decrypt(msg) {
        let msgArray = wavesCrypto.base64Decode(msg)
        const decrypted = wavesCrypto.messageDecrypt(this.sharedKey, msgArray)
        return decrypted
    }

    publicKey() {
        return wavesCrypto.publicKey(this.seed)
    }

    address() {
        return wavesCrypto.address(this.seed, wavesCrypto.TEST_NET_CHAIN_ID)
    }
}

export default SharedKeyEncryption;
// module.exports = SharedKeyEncryption

//test

/* const recieverpubkey = 'AW3ro3MmGL4ojUFPkXA5Wy9WZd69ijuJUuHYtxqhQxYr' // Oracle
const privatekey = 'GcdoCQm5B64qQcyceSr3WkXPrrKNkuncbNUyj73rKavw' //user1
const encryption = new SharedKeyEncryption(recieverpubkey,privatekey)
const ciphertext = encryption.encrypt('salut farouk')
console.log(ciphertext);
const plainText = encryption.decrypt(ciphertext)
console.log(plainText); */