import React, {useState} from 'react'
import "./LoginFormImageComponent.css";

/**
 * todo description
 * @return {JSX.Element}
 * @constructor
 */
export const LoginFormImageComponent = ({randomImage}) => {
    /**
     * Chooses a random image from the image aray
     * @return {*} image
     */
    const determineRandomImage = () => {
        return randomImage[Math.floor(Math.random()*randomImage.length)];
    }

    return (
        <>
            <img className="img"
                 src={determineRandomImage()}
             alt=""/>
        </>
    )
}