/**
 * Builds the form array for the form wizard on the ejot page
 * @return {*[]}
 */
export function buildEjotFormArray() {
    const formArray = [];

    const keysPage1 = [
        {key: "Artikelnummer", value: "", required: true, format: "text"},
        {key: "Abmessung", value: "", required: true, format: "text"},
        {key: "Kilogramm", value: "", required: true, format: "text"},
        {key: "Werkstoff", value: "", required: true, format: "text"},
        {key: "BestellNr", value: "", required: true, format: "text"},
        {key: "Charge", value: "", required: true, format: "text"},
        {key: "Auftragsnummer", value: "", required: false, format: "text"},
        {key: "Einlagerungsdatum", value: "", required: true, format: "date"},
    ];
    const subArrayPage1 = {"title": "", "description": "", "keys": keysPage1};
    formArray.push(subArrayPage1);

    return formArray;
}

export function buildEjotSupplierDataHeaderList() {
    const dataHeaderList = [];

    dataHeaderList["Artikelnummer"] = {key: "Artikelnummer", required: true, format: "text", extern: false};
    dataHeaderList["Abmessung"] = {key: "Abmessung", required: true, format: "text", extern: false};
    dataHeaderList["Kilogramm"] = {key: "Kilogramm", required: true, format: "text", extern: false};
    dataHeaderList["Werkstoff"] = {key: "Werkstoff", required: true, format: "text", extern: false};
    dataHeaderList["BestellNr"] = {key: "BestellNr", required: true, format: "text", extern: false};
    dataHeaderList["Charge"] = {key: "Charge", required: true, format: "text", extern: false};
    dataHeaderList["Auftragsnummer"] = {key: "Auftragsnummer", required: false, format: "text", extern: false};
    dataHeaderList["Einlagerungsdatum"] = {key: "Einlagerungsdatum", required: true, format: "date", extern: false};
    dataHeaderList["Wunschversanddatum"] = {key: "Wunschversanddatum", required: true, format: "date", extern: true};

    return dataHeaderList;
}