import React, {useState} from "react";
import updateImage from "../../../ressources/icons/document-edit-outline.png";
import {Confirm} from "react-st-modal";

/**
 * Needed data: full key, display key, value, encryption variant
 * @return {JSX.Element}
 * @constructor
 */
export const CardComponent = ({title, data, editable, uploadDataToBlockchain, deleteDataFromBlockchain}) => {
    const [editingMode, setEditingMode] = useState(false);

    const updateButtonClicked = () => {
        setEditingMode(!editingMode);
    }

    const saveButtonClicked = () => {
        // Check which values were changed
        const changed = [];
        for (const key in data) {
            if (data[key].modifiedValue !== undefined && data[key].value !== data[key].modifiedValue) {
                const copy = data[key];
                copy.value = copy.modifiedValue;
                changed.push(copy);
            }
        }

        if (uploadDataToBlockchain !== undefined && changed.length !== 0) {
            uploadDataToBlockchain(changed);
        }
    }

    const deleteButtonClicked = async (value) => {
        const text = "Sind Sie sicher, dass Sie den Eintrag \"" + value.displayKey + "\" löschen wollen? \nSie können diese Aktion nicht rückgängig machen.";

        const isConfirm = await Confirm(
            text,
            'Eintrag löschen',
            "Löschen",
            "Lieber nicht"
        );

        if (isConfirm && deleteDataFromBlockchain !== undefined) {
            deleteDataFromBlockchain([value]);
        }
    }

    const inputChanged = (event) => {
        data[event.target.id].modifiedValue = event.target.value;
    }

    const build = () => {
        const array = [];

        for (const key in data) {
            array.push(
                <div className="card-body-row">
                    <div className="card-body-key"> {data[key].displayKey} </div>
                    <div className="card-body-value">
                        {editingMode ?
                            <div className="flex">
                                <input className="full-width" type="text" id={key} name={data[key].key} defaultValue={data[key].value}
                                       onChange={e => inputChanged(e)}/>
                                <button className="button-standard button-red float-right" style={{height: "21px", marginTop: "0"}}
                                        onClick={() =>  deleteButtonClicked(data[key])}>Löschen</button>
                            </div>
                            :
                            data[key].value
                        }
                    </div>
                </div>
            )
        }

        return array;
    }

    return (
        <div className="card">
            <div className="card-title">
                <div className="card-title-row">

                    <div className="card-title-row-left">
                        <h3>{title}</h3>
                    </div>

                    {editable ?
                        <div className="card-title-row-right">
                            <button className="" onClick={() => updateButtonClicked()}>
                                <img className="" src={updateImage} alt={""}/>
                                Ändern
                            </button>
                        </div> : null
                    }

                </div>
            </div>

            <div className="card-body overflow-auto">
                {build()}
                {editingMode ? <button className="button-standard button-green" onClick={() =>  saveButtonClicked()}>Speichern</button> : null}
            </div>
        </div>
    )
}