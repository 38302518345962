import {decryptMessage, encryptMessage} from "../../util/Encryption";
import Utils from "../../extern/piel_js/Utils";
import SharedKeyEncryption from "../../extern/piel_js/sharedKeyEncryption";
import {wavesKeeperSendDataTransaction} from "./WavesKeeperAPI";
import {wavesSignerSendDataTransaction} from "./WavesSignerAPI";
import {scriptInvocation, sendDataTransaction} from "./WavesAPI";

/**
 * Holds the Public Key & Private Key, depending on the login method (Private, Seed, Signer or Keeper)
 */
export class WavesSelectorAPI {
    constructor(loginMethod) {
        this.loginMethod = loginMethod;
        this._keyPair = null;
        this._signer = null;
        this._keeper = null;
    }

    // GETTER & SETTER
    set keyPair(value) {
        this._keyPair = value;
    }

    set keeper(value) {
        this._keeper = value;
    }

    set signer(value) {
        this._signer = value;
    }

    get publicKey() {
        return this._keyPair.publicKey;
    }

    get privateKey() {
        return this._keyPair.privateKey;
    }

    /**
     * Get the address of the account
     * @returns {Promise<*>}
     */
    async getAddress() {
        if (this.loginMethod === "KeyPair") {
            return this._keyPair.address;
        } else if (this.loginMethod === "WavesKeeper") {
            return this._keeper.address;
        } else if (this.loginMethod === "WavesSigner") {
            const {address} = await this._signer.login();
            return address;
        }
    }

    /**
     * the user logged out todo description
     * @returns {Promise<void>}
     */
    async userLoggedOut() {
        if (this.loginMethod === "KeyPair") {
            this.loginMethod = "";
        } else if (this.loginMethod === "WavesKeeper") {

        } else if (this.loginMethod === "WavesSigner") {
            await this._signer.logout();
        }
    }

    /* WAVES FUNCTIONS */
    /**
     * send data to blockchain, the data will in the process not be encrypted
     * @return {Promise<void>}
     */
    async uploadDataToWavesBlockchain(data) {
        if (this.loginMethod === "KeyPair") {
            await sendDataTransaction(data, this._keyPair.privateKey);
        } else if (this.loginMethod === "WavesKeeper") {
            await wavesKeeperSendDataTransaction(data);
        } else if (this.loginMethod === "WavesSigner") {
            await wavesSignerSendDataTransaction(data, this._signer);
        }
    }

    /**
     * send data to blockchain, the data will in the process be encrypted with the private key
     * @param data
     * @return {Promise<void>}
     */
    async uploadDataToWavesBlockchainAndEncryptWithPrivateKey(data) {
        console.log(this.publicKey)

        // Encrypt the Data
        for (const key in data) {
            data[key].value = (await this.encryptMessageWithPrivateKey(data[key].value)).toString();
        }

        console.log(data)
        //await this.sendDataToWavesBlockchain(data);
    }

    async deleteDataFromBlockchain(data) {
        // "The key is being deleted when its type and value are not specified."
        for (let i = 0; i < data.length; i++) {
            data[i].decryptedValue = data[i].value;
            data[i].type = null;
            data[i].value = null;
        }
        return data;
    }

    /**
     * todo description
     * @param dAppAddress
     * @return {Promise<void>}
     */
    async scriptInvocationWithPrivateKey(dAppAddress) {
        if (this.loginMethod === "KeyPair") {
            return await scriptInvocation(dAppAddress, this._keyPair.privateKey, "KoopSC");
        }
    }

    /* ENCRYPTION */
    /**
     * encrypts a bunch of values, overrides the value field with the encrypted value
     * @param data
     * @return {Promise<*>}
     */
    async encryptDataWithPrivateKey(data) {
        const utils = new Utils();
        for (const key in data) {
            if (data[key].encryptionMethod === "sha256") {
                const hashKey = data[key].key + "_" + data[key].encryptionMethod;
                data.push(
                    {key: hashKey, value: utils.toSha256(data[key].value), decryptedValue: data[key].value}
                )
            }

            data[key].decryptedValue = data[key].value;
            data[key].value = (await this.encryptMessageWithPrivateKey(data[key].value)).toString();
        }
        return data;
    }

    /**
     * encrypts a single message
     * @return {Promise<null|*>}
     * @param message
     */
    async encryptMessageWithPrivateKey(message) {
        if (this.loginMethod === "KeyPair") {
            return encryptMessage(message, this._keyPair.privateKey);
        } else {
            return null; // todo throw
        }
    }

    /**
     * todo description
     * @param data
     * @param publicKeyReceiver
     * @return {Promise<void>}
     */
    async encryptDataWithSharedKey(data, publicKeyReceiver) {
        const sharedKeyEncryption = new SharedKeyEncryption(publicKeyReceiver, this.privateKey);

        for (const key in data) {
            data[key].decryptedValue = data[key].value;
            data[key].value = await this.encryptMessageWithSharedKey(data[key].value, sharedKeyEncryption);
        }

        return data;
    }

    /**
     * todo description
     * @param message
     * @param sharedKeyEncryption
     * @return {Promise<*>}
     */
    async encryptMessageWithSharedKey(message, sharedKeyEncryption) {
        return sharedKeyEncryption.encrypt(message);
    }

    /* DECRYPTION */
    /**
     * decrypts a bunch of values, creates encryptedValue field
     * @param data
     * @return {Promise<*>}
     */
    async decryptDataWithPrivateKey(data) {
        for (const key in data) {
            data[key].decryptedValue = await this.decryptMessageWithPrivateKey(data[key].value);
        }
        return data;
    }

    /**
     * decrypts a single message
     * @param message
     * @return {Promise<void>}
     */
    async decryptMessageWithPrivateKey(message) {
        return decryptMessage(message, this._keyPair.privateKey);
    }

    async decryptDataWithSharedKey(data, publicKeyReceiver) {
        const sharedKeyEncryption = new SharedKeyEncryption(publicKeyReceiver, this.privateKey);

        for (const key in data) {
            data[key].decryptedValue = await this.decryptMessageWithSharedKey(data[key].value, sharedKeyEncryption);
        }

        return data;
    }

    async decryptMessageWithSharedKey(message, sharedKeyEncryption) {
        return sharedKeyEncryption.decrypt(message);
    }

    async decryptCooperationDataWithPrivateKey(data) {
        for (const key in data) {
            for (const key2 in data[key]) {
                if (data[key][key2].decryptedValue !== undefined) {
                    data[key][key2].decryptedValue = await this.decryptMessageWithPrivateKey(data[key][key2].value);
                }
            }
        }
        return data;
    }

    async decryptEjotDataWithSharedKey(data, publicKeyReceiver) {
        const sharedKeyEncryption = new SharedKeyEncryption(publicKeyReceiver, this.privateKey);
        for (const key in data) {
            data[key].recieverPublicKey = publicKeyReceiver;
            for (const key2 in data[key]) {
                if (data[key][key2].decryptedValue !== undefined) {
                    data[key][key2].decryptedValue = await this.decryptMessageWithSharedKey(data[key][key2].value, sharedKeyEncryption);
                }
            }
        }
        return data;
    }
}