import React, {useEffect, useState} from "react";
import {FormPageComponent} from "./FormPageComponent";
import "./FormComponent.css";
import {FormImportComponent} from "./FormImportComponent";

/**
 * form wizard
 * <pre>
 *     The array must contain the following information:
 *     [0] {"title": "", "description": "", "keys": [{key, value, required, format}, {key, value, required, format}]}
 *
 *     Example:
 *     const finalArray = [];
 *     const keysPage1 = [
 *          {key: "Verpackungseinheiten", value: "", required: false},
 *          {key: "Versanddatum", value: "", required: true, format: "date"},
 *     ];
 *     const subArrayPage1 = {"title": "Einheiten", "description": "...", "keys": keysPage1};
 *     finalArray.push(subArrayPage1);
 * </pre>
 * @param formContentArray
 * @param pubKey
 * @param submitForm function that will be called when the form was successfully filled
 * @param connectionsList not required
 * @return {JSX.Element}
 * @todo add and remove classes from the buttons (instead of changing the state)
 */
export const FormComponent = ({formContentArray, submitForm, headerList}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextButtonText, setNextButtonText] = useState("Weiter");
    const [nextButtonStyle, setNextButtonStyle] = useState({});
    const [previousButtonDisableStatus, setPreviousButtonDisableStatus] = useState(true);
    const [previousButtonStyle, setPreviousButtonStyle] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        fixStep(0);
        fixButtons();
    },[formContentArray]);

    const submitButtonClicked = async () => {
        if (checkInput())
            return;

        const formData = [];
        for (let i = 0; i < formContentArray.length; i++) {
            formData[i] = [];
            for (let j = 0; j < formContentArray[i].keys.length; j++) {
                formData[i].push(
                    {key: formContentArray[i].keys[j].key, value: formContentArray[i].keys[j].value, format: formContentArray[i].keys[j].format}
                )
            }
        }

        await submitForm(formData)
    }

    const buttonClicked = async (val) => {
        // Return if one of the inputs is empty (invalid) and if the user pressed the "next" button
        if (checkInput() && val > 0) {
            return;
        }

        // Disable Previous Button if the Index is 0
        if (currentIndex+val === 0) {
            setPreviousButtonDisableStatus(true);
            setPreviousButtonStyle({backgroundColor: "gray"});
        } else {
            setPreviousButtonDisableStatus(false);
            setPreviousButtonStyle({backgroundColor: "var(--logout-button-background)"});
        }

        // Disable the Next Button if the Index is size of the array
        if (currentIndex+val >= formContentArray.length - 1) {
            setNextButtonText("Absenden");
            setNextButtonStyle({backgroundColor: "#04AA6D"});
            //setNextButtonDisableStatus(true);
        } else {
            setNextButtonText("Weiter");
            setNextButtonStyle({backgroundColor: "var(--logout-button-background)"});
        }

        await setCurrentIndex(currentIndex+val);
        fixStep(currentIndex+val);
    }

    const fixStep = (currentIndex) =>{
        // Current Step: opacity
        // Previous Steps: green
        const steps = document.getElementsByClassName("step");
        for (let i = 0; i < steps.length; i++) {
            steps[i].className = steps[i].className.replace(" step-active", "");
            steps[i].className = steps[i].className.replace(" step-finish", "");

            if (i === currentIndex) {
                steps[i].className += " step-active";
            } else if (i < currentIndex) {
                steps[i].className += " step-finish";
            }
        }
    }

    /**
     *
     * @return {null|*[]} the steps or if the array length is 1 then null
     */
    const createStep = () => {
        if (formContentArray.length === 1) {
            return null;
        }

        const stepArray = [];
        for (let i = 0; i < formContentArray.length; i++) {
            stepArray.push(<span className="step"/>);
        }
        return stepArray;
    }

    const fixButtons = () => {
        if (formContentArray !== null && formContentArray.length === 1) {
            setNextButtonText("Absenden");
            setNextButtonStyle({backgroundColor: "#04AA6D"});

            const button = document.getElementsByClassName("form-previous-button");
            button[0].className += " hidden";
        }
    }

    /**
     * Creates a placeholder text if no data is available
     * @return {JSX.Element}
     */
    const createPlaceholder = () => {
        return (
            <>
                Ein Fehler ist aufgetreten beim Laden des Formulares.
            </>
        )
    }

    /**
     * Checks the input for empty strings
     * @return {boolean} true if found something that is required to be filled, false otherwise
     */
    const checkInput = () => {
        const tabs = document.getElementsByClassName('tab-input');
        let invalidFound = false;

        // Add the invalid className when the field is required and the value is empty
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].className = tabs[i].className.replace(" tab-invalid", "");
            if (formContentArray[currentIndex].keys[tabs[i].id].required && tabs[i].value.length === 0) {
                tabs[i].className += " tab-invalid";
                invalidFound = true;
            }
        }

        return invalidFound;
    }

    return (
        <div className="form full-height full-width">
            {formContentArray === null || formContentArray.length === 0 ? createPlaceholder() :
                <>
                    <FormImportComponent submitForm={submitForm} headerList={headerList}/><br/>
                    <FormPageComponent arr={formContentArray[currentIndex]} reload={reload}/>

                    <div className="overflow-auto float-right">
                        <button className="button-standard form-previous-button" type="button" disabled={previousButtonDisableStatus} style={previousButtonStyle}
                                onClick={() => buttonClicked(-1)}>
                            Zurück
                        </button>
                        <button className="button-standard button-brown next-button" type="button" style={nextButtonStyle}
                                onClick={() => nextButtonText === "Weiter" ? buttonClicked(1) : submitButtonClicked()}>
                            {nextButtonText}
                        </button>
                    </div>

                    <div className="flex flex-center text-center steps">
                        {createStep()}
                    </div>
                </>
            }
        </div>
    )
}