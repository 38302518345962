import {AccountPublicInformation} from "../@shared/api/waves/AccountPublicInformation";
import SharedKeyEncryption from "../@shared/extern/piel_js/sharedKeyEncryption";
import {getAccountDataList, getAddressFromPublicKey} from "../@shared/api/waves/WavesAPI";

export async function getAccountData(WavesSelectorAPI) {
    const returnObject = {};

    const accountInformation = new AccountPublicInformation(await WavesSelectorAPI.getAddress(), WavesSelectorAPI.publicKey);
    await accountInformation.updateAllData();

    const accountDataList = accountInformation.accountDataList;
    const transactionList = accountInformation.transactionList;
    const filteredAccountDataList = [];

    // Connections
    const connectionSupplierIdentifier = "connection_ejotSupplier_";
    const connectionSupplierArr = [];

    for (const key in accountDataList) {
        // Connection
        if (accountDataList[key].key.startsWith(connectionSupplierIdentifier)) {
            accountDataList[key]["keyKey"] = accountDataList[key].key.substr(connectionSupplierIdentifier.length);
            connectionSupplierArr.push(accountDataList[key]);
        } else if (accountDataList[key].key.startsWith("connection_")) {
            continue; // todo remove after refactor
        } else {
            const keySplit = accountDataList[key].key.split("_");
            const keyIdentifier =  keySplit[0] + "_" + keySplit[1] + "_" + keySplit[2];
            const keyKey = keySplit[3];
            const keyPubKey = keySplit[2];

            const sharedKeyEncryption = new SharedKeyEncryption(keyPubKey, WavesSelectorAPI.privateKey); // todo the class is created again with each iteration
            const decryptedValue = await WavesSelectorAPI.decryptMessageWithSharedKey(accountDataList[key].value, sharedKeyEncryption);

            if (filteredAccountDataList[keyIdentifier] === undefined) {
                filteredAccountDataList[keyIdentifier] = [];
                filteredAccountDataList[keyIdentifier].key = keyIdentifier;
            }

            filteredAccountDataList[keyIdentifier][keyKey] = {
                key: accountDataList[key].key, value: accountDataList[key].value,
                keyPubKey: keyPubKey, keyKey: keyKey, keyIdentifier: keyIdentifier, decryptedValue: decryptedValue,
                origin: "self"
            }
        }
    }

    // Decrypt Supplier Connection
    const decryptedConnectionSupplierArr = await WavesSelectorAPI.decryptDataWithPrivateKey(connectionSupplierArr);

    // Get the data from the supplier connections
    if (decryptedConnectionSupplierArr.length > 0) {
        for (let i = 0; i < decryptedConnectionSupplierArr.length; i++) {
            const address = getAddressFromPublicKey(decryptedConnectionSupplierArr[i].decryptedValue);
            const supplierEncryptedAccountDataList = await getAccountDataList(address);

            // Decrypt
            const sharedKeyEncryption = new SharedKeyEncryption(decryptedConnectionSupplierArr[i].decryptedValue, WavesSelectorAPI.privateKey);
            for (const key in supplierEncryptedAccountDataList) {
                // Filter // todo general function
                if (supplierEncryptedAccountDataList[key].key.startsWith("connection_"))
                    continue;

                // Key: 20210722_043a718774c572bd8a25adbeb1bfcd5c0256ae11cecf9f9c3f925d0e52beaf89_HrgRdsxrSvtQMBATpEn8knXiYJbRX1jHzN44ccp8NXRB_ArtikelNr
                const keySplit = supplierEncryptedAccountDataList[key].key.split("_");
                const keyIdentifier =  keySplit[0] + "_" + keySplit[1] + "_" + keySplit[2];
                const keyKey = keySplit[3];
                const keyPubKey = keySplit[2];
                const decryptedValue = await WavesSelectorAPI.decryptMessageWithSharedKey(supplierEncryptedAccountDataList[key].value, sharedKeyEncryption);

                if (filteredAccountDataList[keyIdentifier] === undefined) {
                    filteredAccountDataList[keyIdentifier] = [];
                    filteredAccountDataList[keyIdentifier].key = keyIdentifier;
                }

                filteredAccountDataList[keyIdentifier][keyKey] = {
                    key: supplierEncryptedAccountDataList[key].key, value: supplierEncryptedAccountDataList[key].value,
                    keyPubKey: keyPubKey, keyKey: keyKey, keyIdentifier: keyIdentifier, decryptedValue: decryptedValue,
                    origin: "extern"
                }
            }
        }
    }

    returnObject.suppliersData = filteredAccountDataList;
    returnObject.connectionSupplierList = decryptedConnectionSupplierArr;
    returnObject.accountInformation = accountInformation;
    returnObject.transactionList = transactionList;

    return returnObject;
}