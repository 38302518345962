import React, {useEffect} from "react";

export const MainPageMenuComponent = ({content, changeActivePage}) => {
    useEffect(() => {
        buttonClicked(content[0].identifier)
    }, []);

    /**
     * menu button was clicked (any)
     * @param value the value of the button clicked
     */
    const buttonClicked = (value) => {
        changedButtonStyle(value);
        changeActivePage(value);
    }

    /**
     * Change the style of the clicked button (and of all the other buttons)
     * @param value the value of the button clicked
     */
    const changedButtonStyle = (value) => {
        const buttons = document.getElementsByClassName('mainpage-menu-button');

        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].value === value) {
                buttons[i].style.backgroundColor = "var(--card-menu-button-selected-background)";
                buttons[i].style.color = "var(--card-menu-button-selected-text)";
                buttons[i].style.borderLeft = "4px solid var(--card-menu-button-selected-border)";
            } else {
                buttons[i].style.backgroundColor = "inherit";
                buttons[i].style.color = "inherit";
                buttons[i].style.borderLeft = "none";
            }
        }
    }

    const buildButtons = () => {
        const arr = [];

        for (let i = 0; i < content.length; i++) {
            arr.push(buildButton(content[i]));
        }

        return arr;
    }

    /**
     * Build a button
     * @return {JSX.Element}
     * @todo distinguish between one image, two images or no image
     */
    const buildButton = (content) => {
        return (
            <button className="card-menu-button mainpage-menu-button" value={content.identifier} onClick={() => buttonClicked(content.identifier)}>
                <img className="card-menu-btnImage" src={content.defaultImage} alt={""} style={{height: "24px", width: "24px"}}/>
                <span className="card-menu-btnText">{content.title}</span>
                <img className="card-menu-btnImage" src={content.defaultImage} alt={""} style={{height: "24px", width: "24px"}}/>
            </button>
        )
    }

    return (
        <div className="card-menu image-changer">
            {buildButtons()}
        </div>
    )
}