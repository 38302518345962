import React, {useState} from 'react'
import "./LoginFormSelectionComponent.css";
import "./LoginSelectionButton.css"
import {LoginPrivateKeyComponent} from "./selectionpages/LoginPrivateKeyComponent";
import {LoginSeedComponent} from "./selectionpages/LoginSeedComponent";
import {LoginWavesKeeperComponent} from "./selectionpages/LoginWavesKeeperComponent";
import {LoginWavesSignerComponent} from "./selectionpages/LoginWavesSignerComponent";
import {LoginCreateAccountComponent} from "./selectionpages/LoginCreateAccountComponent";

// todo center
export const LoginFormSelectionComponent = ({loginKeyPair, loginWavesKeeper, loginWavesSigner}) => {

    return (
        <div className="login-form-selection">
            <br/>
            <LoginPrivateKeyComponent login={loginKeyPair}/>

            <br/>
            <hr className="hr-text" data-content="ODER"/>
            <br/>

            <LoginSeedComponent login={loginKeyPair}/>

            <br/>
            <hr className="hr-text" data-content="ODER"/>
            <br/>

            <LoginCreateAccountComponent login={loginKeyPair}/>

            {/*<LoginWavesKeeperComponent login={loginWavesKeeper}/>
            <LoginWavesSignerComponent login={loginWavesSigner}/>*/}
        </div>
    )
}