import React, {useState} from "react";
import {TransactionsContentComponent} from "./TransactionsContentComponent";
import {TransactionsMenuComponent} from "./TransactionsMenuComponent";
import "./TransactionsComponent.css";

/**
 * displays the transaction content and the transaction menu to select a transaction
 * @param transactionList encrypted transaction list
 * @return {JSX.Element}
 * @constructor
 */
export const TransactionsComponent = ({transactionList}) => {
    const [activeTransaction, setActiveTransaction] = useState("");

    /**
     * sets the transaction that will be displayed
     * @param transaction next transaction to display
     */
    const updateActiveTransaction = (transaction) => {
        setActiveTransaction(transaction);
    }

    /**
     * Converts the type to a string
     * @param type
     * @returns {string}
     */
    const typeToString = (type) => {
        if (type === 12) {
            return "Daten";
        } else if (type === 4) {
            return "Überweisung";
        } else if (type === 16) {
            return "Skript-Aufruf"
        } else {
            return "Unbekannter Typ";
        }
    }

    const buildPlaceholder = () => {
        return (
            <div className="placeholder placeholder-primary">
                Keine Transaktionen vorhanden.
            </div>
        )
    }

    return (
        <div className="transactions-wrapper">
            {transactionList.length === 0 ? buildPlaceholder()
                :
                <>
                    <TransactionsMenuComponent
                        transactionList={transactionList}
                        updateActiveTransaction={updateActiveTransaction}
                        typeToString={typeToString}
                    />

                    <TransactionsContentComponent
                        transaction={activeTransaction}
                        typeToString={typeToString}
                    />
                </>
            }


        </div>
    )
}