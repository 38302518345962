import React, {useEffect, useState} from 'react';
import { Confirm } from 'react-st-modal';
import updateImage from "../../ressources/icons/document-edit-outline.png"

/**
 * A card consisting of a title and one or more rows
 * @param title title of the card
 * @param objectArr object array in the format {[0] = row title, [1] = row value}
 * @param editable update button
 * @returns {JSX.Element} Card
 */
export const CardComponent = ({title, objectArr, editable = false}) => {
    const [editingMode, setEditingMode] = useState(false);

    useEffect(() => {

    }, []);

    const updateButtonClicked = () => {
        setEditingMode(!editingMode);
    }

    const saveButtonClicked = () => {

    }

    const deleteButtonClicked = async (value) => {
        const text = "Sind Sie sicher, dass Sie den Eintrag \"" + value + "\" löschen wollen? \nSie können diese Aktion nicht rückgängig machen.";

        const isConfirm = await Confirm(
            text,
            'Eintrag löschen',
            "Sicher!",
            "Lieber nicht"
        );

        if (isConfirm) {
            alert(value);
        }
    }

    if (Object.entries(objectArr).length === 0) {
        return null;
    }

    return (
        <div className="card">
            <div className="card-title">
                <div className="card-title-row">

                    <div className="card-title-row-left">
                        <h3>{title}</h3>
                    </div>

                    {editable ?
                        <div className="card-title-row-right">
                            <button className="" onClick={() => updateButtonClicked()}>
                                <img className="" src={updateImage} alt={""}/>
                                Ändern
                            </button>
                        </div> : null
                    }

                </div>
            </div>

            <div className="card-body overflow-auto">
                {Object.entries(objectArr).map(item =>
                    <div className="card-body-row">
                        <div className="card-body-key"> {item[0]} </div>
                        <div className="card-body-value">
                            {editingMode ?
                                <div className="flex">
                                    <input className="full-width" type="text" id={item[0]} name={item[0]} value={item[1]}/>
                                    <button className="button-standard button-red float-right" style={{height: "21px", marginTop: "0"}} onClick={() =>  deleteButtonClicked(item[0])}>Löschen</button>
                                </div>
                                :
                                item[1]
                            }
                        </div>
                    </div>
                )}
                {editingMode ? <button className="button-standard" onClick={() =>  saveButtonClicked()}>Speichern</button> : null}
            </div>
        </div>
    )
}