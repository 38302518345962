import {useEffect, useState} from "react";

/**
 *
 * @param arr
 * @param reload forces the page to reload and put the new values in the input
 * @return {JSX.Element}
 * @constructor
 */
export const FormPageComponent = ({arr, reload}) => {
    useEffect(() => {
        // Set the value
        const tabs = document.getElementsByClassName('tab-input');
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].value = arr.keys[tabs[i].id].value;
        }
    }, [arr, reload]);

    /**
     * the input has changed, so change the corresponding value in the array
     * @param event
     */
    const inputChanged = (event) => {
        arr.keys[event.target.id].value = event.target.value;
    }

    /**
     * builds the inputs
     * @return {*[]}
     */
    const build = () => {
        const inputArray = [];

        for (let i = 0; i < arr.keys.length; i++) {
            inputArray.push(
                <p> {arr.keys[i].key} {arr.keys[i].required ? " (Pflichtfeld)" : ""}
                    {buildInput(arr.keys[i], i)}
                </p>
            )
        }

        return inputArray;
    }

    /**
     * build a input
     * @param element
     * @param index
     * @return {JSX.Element}
     */
    const buildInput = (element, index) => {
        if (element.format === "date") {
            return (<input className="full-width tab-input" type="date" id={index} name={element.key} onChange={e => inputChanged(e)}/>);
        } else {
            return (<input className="full-width tab-input" type="text" id={index} name={element.key} onChange={e => inputChanged(e)}/>);
        }
    }

    return (
        <>
            <div key={arr.title} className="tab"><h1>{arr.title}</h1>
                <h3>{arr.description}</h3>
                {build()}
            </div>
        </>
    )
}