import React from "react";
import {chooseDecryptedValueOrValue} from "../../util/conversions/ConversionDataKey";

/**
 * Builds an table from an dataList and from an headerList with the following format:
 * <pre>
 *     dataList:
 *     dataList[key] = {}
 *
 *     headerList:
 *     headerList[key] = {key: key, required: false, format: "text"}; // title: "title" is optional
 * </pre>
 * @param dataList
 * @param headerList
 * @param rowClicked function
 * @param rowEditable
 * @return {JSX.Element}
 * @constructor
 */
export const DataTableComponent = ({dataList, headerList, rowClicked, rowEditable = false}) => {
    const generateRows = (data) => {
        const rows = [];
        for (const key in data) {
            if (rowEditable) {
                rows.push(<tr onClick={() => rowClicked(data[key])}>{generateRow(data[key])}</tr>);
            } else {
                rows.push(<tr>{generateRow(data[key])}</tr>);
            }
        }
        return rows;
    }

    const generateRow = (data) => {
        const row = [];

        for (const key in headerList) {
            if (data[key] !== undefined) {
                row.push(<td>{chooseDecryptedValueOrValue(data[key])}</td>)
            } else {
                row.push(<td>-</td>)
            }
        }

        return row;
    }

    const buildTable = (data, headers) => {
        const rows = generateRows(data);

        return (
            <table className="table cooperation-data-table full-width text-center">
                <tr>
                    {buildTableHeader()}
                </tr>

                {rows.map((row) =>
                    <>{row}</>
                )}
            </table>
        )
    }

    const buildTableHeader = () => {
        const arr = [];

        for (const key in headerList) {
            if (headerList[key].title !== undefined && headerList[key].title !== null && headerList[key].title.length > 0) {
                arr.push(<th>{headerList[key].title}</th>)
            } else {
                arr.push(<th>{headerList[key].key}</th>)
            }
        }

        return arr;
    }

    const build = () => {
        return (
            <>
                {buildTable(dataList, headerList)}
            </>
        )
    }

    const buildPlaceholder = () => {
        return (
            <>
                <br/><br/>
                Keine Daten vorhanden.
            </>
        )
    }

    return (
        <>
            {dataList === undefined || Object.keys(dataList).length === 0 ? buildPlaceholder() : build()}
        </>
    )


}