/**
 * Convers a timestamp to a readable date
 * @param timestamp
 * @return {string} date with date month year hour:min:sec
 */
export function convertTimestampToDate(timestamp) {
    const time = new Date(timestamp);
    const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    const year = time.getFullYear();
    const month = months[time.getMonth()];
    const date = time.getDate();
    const hour = time.getHours();
    const min = time.getMinutes();
    const sec = time.getSeconds();
    return date + ' ' + month + ' ' + year + ' ' + addZeroToSingleDigit(hour) + ':' + addZeroToSingleDigit(min) + ':' + addZeroToSingleDigit(sec);
}

/**
 * Adds a zero in front of a single digit
 * @param digit
 * @return {string|*} digit or 0digit
 */
function addZeroToSingleDigit(digit) {
    if (digit.toString().length === 1) {
        return "0" + digit;
    } else {
        return digit;
    }
}

/**
 * Get the current timestamp
 */
export function getCurrentTimestamp() {
    return Date.now();
}

export function getCurrentTimestampMidnight() {
    const date = new Date();
    date.setHours(0,0,0,0);
    return date.getTime();
}

/**
 * @todo naming
 */
export function getCurrentDatePart() {
    const time = new Date(getCurrentTimestamp());
    const year = time.getFullYear();
    const month = time.getMonth();
    const date = time.getDate();
    return addZeroToSingleDigit(date) + "." + addZeroToSingleDigit(month+1) + "." + year;
}

export function getCurrentDateUTC() {
    const time = new Date(getCurrentTimestamp());
    const year = time.getFullYear();
    const month = time.getMonth();
    const date = time.getDate();
    return year + "-" + addZeroToSingleDigit(month+1) + "-" + addZeroToSingleDigit(date);
}

export function convertDateToDatePart(date) {
    // todo
    return date.getFullYear() + "-" + addZeroToSingleDigit(date.getMonth()+1) + "-" + addZeroToSingleDigit(date.getDate());
    //return addZeroToSingleDigit(date.getDate()) + "." + addZeroToSingleDigit(date.getMonth()+1) + "." + date.getFullYear();
}

/**
 * Get the current date in format "YYYYMMDD"
 * @return {string} YYYYMMDD
 */
export function getCurrentDate() {
    const time = new Date(getCurrentTimestamp());
    const year = time.getFullYear();
    const month = time.getMonth()+1;
    const date = time.getDate();

    return year + "" + addZeroToSingleDigit(month) + "" + addZeroToSingleDigit(date);
}

/**
 *
 * @param dateNow
 * @param dateFuture
 * @return {string}
 * @todo doesnt work currently
 */
export function calcTimeFromTo(dateNow, dateFuture) {
    let diffInMilliSeconds  = Math.abs(dateFuture - dateNow);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    return hours + "h " + minutes + "min";
}

/**
 * Converts an utc string into an date string, eg. 2021-08-21 -> 21.08.2021
 * @param utc
 * @return {string}
 */
export function convertUTCToDate(utc) {
    // todo candidate for devmenu
    const split = utc.split("-");
    if (split[1] === undefined || split[2] === undefined) {
        return utc + " (*)";
    }
    return split[2] + "." + split[1] + "." + split[0];
}