import {convertTimestampToDate} from "../../util/conversions/ConversionsTime";
import React from "react";
import dataUploadImage from "../../ressources/icons/datei-hochladen.png";

export const TransactionsMenuComponent = ({transactionList, typeToString, updateActiveTransaction}) => {
    /**
     * button clicked
     * @param transaction the transaction whose button was pressed
     * @param value the value of the button clicked
     */
    const buttonClicked = (transaction, value) => {
        changedButtonStyle(value);
        updateActiveTransaction(transaction)
    }

    /**
     * Change the style of the clicked button (and of all the other buttons)
     * @param value the value of the button clicked
     */
    const changedButtonStyle = (value) => {
        const buttons = document.getElementsByClassName('transaction-menu-button');

        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].value === value) {
                buttons[i].style.backgroundColor = "var(--card-menu-button-selected-background)";
                buttons[i].style.color = "var(--card-menu-button-selected-text)";
                buttons[i].style.borderLeft = "4px solid var(--card-menu-button-selected-border)";
            } else {
                buttons[i].style.backgroundColor = "inherit";
                buttons[i].style.color = "inherit";
                buttons[i].style.borderLeft = "none";
            }
        }
    }

    /**
     * Generate the menu button text
     * @param transaction
     * @returns {JSX.Element} Type <br> Date
     */
    const generateButtonText =(transaction) => {
        return (
            <>
                {typeToString(transaction.type)}
                <br/>
                {convertTimestampToDate(transaction.timestamp)}
            </>
        )
    }

    return (
        <div className="card-menu">
            {transactionList.map(transaction =>
                <div key={transaction.id} className="">
                    <button className="card-menu-button transaction-menu-button" value={transaction.id} onClick={() => buttonClicked(transaction, transaction.id)}>
                        <img className="card-menu-btnImage" src={dataUploadImage}/>
                        <span className="card-menu-btnText">{generateButtonText(transaction)}</span>
                    </button>
                </div>
            )}
        </div>
    )
}